import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-5 pl-2" };
const _hoisted_2 = { class: "font-bold" };
const _hoisted_3 = { key: 0, class: "flex flex-col" };
const _hoisted_4 = { class: "blockquote italic" };
const _hoisted_5 = { key: 1, class: "mt-1 flex flex-col" };
const _hoisted_6 = { class: "blockquote italic" };
import { format } from 'date-fns';
export default /*@__PURE__*/ _defineComponent({ __name: 'company-info-achievements', props: { lastWeeklyUpdate: {}, secondToLastWeeklyUpdate: {} }, setup(__props) { const props = __props; const updates = []; if (props.lastWeeklyUpdate) {
        updates.push({ title: `Semana pasada (${format(props.lastWeeklyUpdate.startDate, 'd/MM')})`, weeklyUpdate: props.lastWeeklyUpdate });
    } if (props.secondToLastWeeklyUpdate) {
        updates.push({ title: `Semana antepasada (${format(props.secondToLastWeeklyUpdate.startDate, 'd/MM')})`, weeklyUpdate: props.secondToLastWeeklyUpdate });
    } return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(updates, update => { return _createElementVNode("div", { key: update.weeklyUpdate.id, class: "flex flex-col gap-1" }, [_createElementVNode("p", _hoisted_2, _toDisplayString(update.title), 1), _createElementVNode("p", null, _toDisplayString(update.weeklyUpdate.goalAchieved ? '✅ Meta semanal lograda' : '❌ Meta semanal no lograda'), 1), update.weeklyUpdate.achievements ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("p", null, " Logros: ", -1)), _createElementVNode("p", _hoisted_4, _toDisplayString(update.weeklyUpdate.achievements), 1)])) : _createCommentVNode("", true), update.weeklyUpdate.discoveries ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_cache[1] || (_cache[1] = _createElementVNode("p", null, " Descubrimientos: ", -1)), _createElementVNode("p", _hoisted_6, _toDisplayString(update.weeklyUpdate.discoveries), 1)])) : _createCommentVNode("", true)]); }), 64))]); }; } });
