import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex w-full max-w-3xl flex-col gap-10" };
const _hoisted_2 = { class: "flex flex-col gap-5" };
const _hoisted_3 = { class: "text-xl text-slate-300" };
const _hoisted_4 = { class: "flex justify-between gap-6" };
const _hoisted_5 = { class: "flex flex-col text-3xl text-slate-50" };
const _hoisted_6 = { class: "flex gap-2" };
const _hoisted_7 = { class: "flex flex-col gap-14 rounded-xl bg-pv-blue-900 px-6 py-8" };
const _hoisted_8 = { class: "px-4 py-2 text-slate-400" };
const _hoisted_9 = { class: "px-4 py-2" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { class: "mb-1 text-xl/7 font-medium text-slate-50" };
const _hoisted_12 = { key: 0 };
const _hoisted_13 = { class: "flex justify-center" };
import { computed, ref } from 'vue';
import { parseISO } from 'date-fns';
import KalioRichText from '@/components/shared/kalio-rich-text.vue';
import { formatCurrency, formatPercentage, formatNumber, formatDate } from '@/filters';
import ShareCompanyUpdateModal from './share-company-update-modal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'company-update',
    props: {
        company: {},
        companyUpdate: {},
        previousCompanyUpdate: {}
    },
    setup(__props) {
        const props = __props;
        const formattedMonth = computed(() => {
            const parsedMonth = parseISO(props.companyUpdate.month);
            return formatDate(parsedMonth, { month: 'long', year: 'numeric' });
        });
        const metricWithVariationNames = [
            'grossMargin',
            'revenue',
            'annualRecurringRevenue',
            'customerLifetimeValue',
            'customerAcquisitionCost',
            'cashInBank',
            'netCashBurn',
            'runway',
            'headcount',
            'totalClients',
        ];
        function metricWithVariation(name, formatFunction) {
            const value = props.companyUpdate[name];
            const formattedValue = formatFunction(value);
            const previousValue = props.previousCompanyUpdate?.[name];
            if (value === null || !previousValue)
                return formattedValue;
            const formattedChange = formatPercentage((value - previousValue) / previousValue, { signDisplay: 'exceptZero' });
            return `${formattedValue} (${formattedChange})`;
        }
        const metrics = computed(() => [
            {
                name: 'Margen Bruto',
                value: metricWithVariation('grossMargin', (value) => `${value} %`),
            },
            {
                name: 'Ingreso mensual',
                value: metricWithVariation('revenue', formatCurrency),
            },
            {
                name: 'ARR',
                value: formatCurrency(props.companyUpdate.annualRecurringRevenue),
            },
            {
                name: 'LTV',
                value: metricWithVariation('customerLifetimeValue', formatCurrency),
            },
            {
                name: 'CAC',
                value: metricWithVariation('customerAcquisitionCost', formatCurrency),
            },
            {
                name: 'Cash in bank',
                value: metricWithVariation('cashInBank', formatCurrency),
            },
            {
                name: 'Net cash burn',
                value: metricWithVariation('netCashBurn', formatCurrency),
            },
            {
                name: 'Runway',
                value: `${formatNumber(props.companyUpdate.runway)} meses`,
            },
            {
                name: 'Headcount',
                value: metricWithVariation('headcount', formatNumber),
            },
            {
                name: 'Clientes',
                value: metricWithVariation('totalClients', formatNumber),
            },
        ].filter((metric) => metric.value !== null));
        const richTexts = computed(() => [
            {
                name: 'Highlights',
                value: props.companyUpdate.highlights,
            },
            {
                name: 'Lowlights',
                value: props.companyUpdate.lowlights,
            },
            {
                name: 'Ofertas de trabajo',
                value: props.companyUpdate.jobOfferings,
            },
            {
                name: 'Ronda de financiamiento',
                value: props.companyUpdate.fundingRound,
            },
            {
                name: 'Asks',
                value: props.companyUpdate.asks,
            },
        ].filter((text) => text.value));
        const plainTexts = computed(() => [
            {
                name: 'Highlights',
                value: props.companyUpdate.plainHighlights,
            },
            {
                name: 'Lowlights',
                value: props.companyUpdate.plainLowlights,
            },
            {
                name: 'Asks',
                value: props.companyUpdate.plainAsks,
            },
        ].filter((text) => text.value));
        const shareCompanyUpdateModalOpen = ref(false);
        return (_ctx, _cache) => {
            const _component_kalio_back_button = _resolveComponent("kalio-back-button");
            const _component_kalio_button = _resolveComponent("kalio-button");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_kalio_back_button, {
                    class: "self-start",
                    href: "/tracking/company_updates"
                }),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h2", _hoisted_3, _toDisplayString(props.company.name), 1),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("h1", _hoisted_5, [
                            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-medium" }, " Actualización mensual, ", -1)),
                            _createElementVNode("p", null, _toDisplayString(formattedMonth.value), 1)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_kalio_button, {
                                href: `/tracking/company_updates/${props.companyUpdate.id}/edit`,
                                variant: "secondary",
                                class: "self-start"
                            }, {
                                default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode(" Editar ")
                                ])),
                                _: 1
                            }, 8, ["href"]),
                            _createVNode(_component_kalio_button, {
                                variant: "primary",
                                class: "self-start",
                                onClick: _cache[0] || (_cache[0] = ($event) => (shareCompanyUpdateModalOpen.value = true))
                            }, {
                                default: _withCtx(() => _cache[4] || (_cache[4] = [
                                    _createTextVNode(" Compartir ")
                                ])),
                                _: 1
                            }),
                            (shareCompanyUpdateModalOpen.value)
                                ? (_openBlock(), _createBlock(ShareCompanyUpdateModal, {
                                    key: 0,
                                    "company-name": props.company.name,
                                    month: formattedMonth.value,
                                    metrics: metrics.value,
                                    "plain-texts": plainTexts.value,
                                    onClose: _cache[1] || (_cache[1] = ($event) => (shareCompanyUpdateModalOpen.value = false))
                                }, null, 8, ["company-name", "month", "metrics", "plain-texts"]))
                                : _createCommentVNode("", true)
                        ])
                    ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                        _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "mb-1 text-xl/7 font-medium text-slate-50" }, " Métricas ", -1)),
                        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mb-8 h-px w-full bg-slate-400" }, null, -1)),
                        _createElementVNode("table", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metrics.value, (metric, index) => {
                                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                    _createElementVNode("td", _hoisted_8, _toDisplayString(metric.name), 1),
                                    _createElementVNode("td", _hoisted_9, _toDisplayString(metric.value), 1)
                                ]));
                            }), 128))
                        ])
                    ]),
                    _createElementVNode("div", null, [
                        _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "mb-1 text-xl/7 font-medium text-slate-50" }, " Pivot ", -1)),
                        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mb-8 h-px w-full bg-slate-400" }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(props.companyUpdate.pivot ? 'Sí' : 'No'), 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(richTexts.value, (richText, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (richText.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createElementVNode("h3", _hoisted_11, _toDisplayString(richText.name), 1),
                                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "mb-8 h-px w-full bg-slate-400" }, null, -1)),
                                    _createVNode(KalioRichText, {
                                        class: "text-sm/tight text-slate-50",
                                        "rich-text": richText.value
                                    }, null, 8, ["rich-text"])
                                ]))
                                : _createCommentVNode("", true)
                        ], 64));
                    }), 128)),
                    (props.companyUpdate.reportUrl)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _cache[10] || (_cache[10] = _createElementVNode("h3", { class: "mb-1 text-xl/7 font-medium text-slate-50" }, " Reporte en PDF ", -1)),
                            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "mb-8 h-px w-full bg-slate-400" }, null, -1)),
                            _createElementVNode("div", _hoisted_13, [
                                _createVNode(_component_kalio_button, {
                                    label: "Abrir Reporte",
                                    href: props.companyUpdate.reportUrl,
                                    target: "_blank",
                                    variant: "secondary"
                                }, null, 8, ["href"])
                            ])
                        ]))
                        : _createCommentVNode("", true)
                ])
            ]));
        };
    }
});
