import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "grid grid-cols-3 gap-10"
};
const _hoisted_2 = { class: "col-span-2 flex w-full flex-col gap-8" };
const _hoisted_3 = { class: "flex flex-col gap-2.5" };
const _hoisted_4 = { class: "text-3xl font-bold" };
const _hoisted_5 = { class: "italic text-pv-gray" };
const _hoisted_6 = { class: "flex flex-col rounded border border-pv-yellow p-4" };
const _hoisted_7 = { class: "flex flex-col gap-8" };
const _hoisted_8 = { class: "flex flex-col gap-6" };
const _hoisted_9 = {
    key: 0,
    class: "flex flex-col"
};
const _hoisted_10 = { class: "mt-2" };
const _hoisted_11 = { class: "flex flex-col" };
const _hoisted_12 = { class: "mt-2" };
const _hoisted_13 = { class: "flex flex-col" };
const _hoisted_14 = { class: "mt-2" };
const _hoisted_15 = { class: "flex flex-col" };
const _hoisted_16 = { class: "flex flex-col" };
const _hoisted_17 = { class: "flex flex-col" };
const _hoisted_18 = { class: "flex flex-col" };
const _hoisted_19 = {
    key: 1,
    class: "flex flex-col gap-2"
};
const _hoisted_20 = {
    key: 0,
    class: "flex flex-col gap-8"
};
const _hoisted_21 = { class: "flex flex-col gap-2" };
const _hoisted_22 = { class: "flex flex-col gap-2" };
const _hoisted_23 = { class: "flex flex-col gap-2" };
const _hoisted_24 = { key: 1 };
const _hoisted_25 = { class: "mt-8 flex flex-col gap-6" };
const _hoisted_26 = { class: "flex flex-col" };
const _hoisted_27 = { class: "flex flex-col" };
const _hoisted_28 = { class: "flex flex-col gap-8" };
const _hoisted_29 = {
    key: 0,
    class: "flex flex-col gap-2 rounded border p-2"
};
const _hoisted_30 = { class: "flex flex-col" };
const _hoisted_31 = { class: "text-xs" };
const _hoisted_32 = { class: "flex flex-col" };
const _hoisted_33 = { class: "text-slate-50" };
const _hoisted_34 = { class: "flex flex-col" };
import { computed, ref, toRefs } from 'vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { qualificationsApi, } from '@/build_sprint_pack/api/internal/build_sprint_qualifications';
import { updatesApi } from '@/build_sprint_pack/api/internal/build_sprint_updates';
import KalioRichText from '@/components/shared/kalio-rich-text.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'build-sprint-update-review',
    props: {
        updateId: {}
    },
    setup(__props) {
        const props = __props;
        const { updateId } = toRefs(props);
        const { data: update, refetch } = useQuery(['update', updateId], () => updatesApi.show(updateId.value));
        const sentAt = computed(() => update.value && new Date(update.value.createdAt).toLocaleDateString('es'));
        const buildSprintProject = computed(() => update.value?.buildSprintProject);
        const buildSprintQualification = computed(() => update.value?.buildSprintQualification);
        const qualificationOptions = ['awful', 'not_good', 'average', 'great'];
        const form = ref({
            qualification: undefined,
            message: undefined,
        });
        const { mutate, isLoading } = useMutation((data) => {
            if (buildSprintQualification.value)
                return qualificationsApi.update(buildSprintQualification.value.id, data);
            return qualificationsApi.create(data);
        });
        function onSubmit(_values, { resetForm }) {
            if (form.value.qualification === undefined || form.value.message === undefined)
                return;
            const qualificationData = {
                buildSprintUpdateId: updateId.value,
                qualification: form.value.qualification,
                message: form.value.message,
            };
            mutate(qualificationData, {
                onSuccess: () => {
                    resetForm();
                    refetch();
                },
            });
        }
        return (_ctx, _cache) => {
            const _component_kalio_select_input = _resolveComponent("kalio-select-input");
            const _component_kalio_long_text_input = _resolveComponent("kalio-long-text-input");
            const _component_kalio_button = _resolveComponent("kalio-button");
            const _component_vee_form = _resolveComponent("vee-form");
            return (_unref(update) && buildSprintProject.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("h1", _hoisted_4, _toDisplayString(buildSprintProject.value.name), 1),
                            _createElementVNode("span", _hoisted_5, " Enviado el " + _toDisplayString(sentAt.value), 1)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-bold" }, " Meta del Build Sprint ", -1)),
                            _createElementVNode("span", null, _toDisplayString(buildSprintProject.value.goal), 1)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                            _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "border-b-2 border-pv-yellow text-2xl" }, " Reporte semanal ", -1)),
                            _createElementVNode("div", _hoisted_8, [
                                (_unref(update).summary)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-bold" }, " 🤖 Resumen ", -1)),
                                        _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(update).summary), 1)
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_11, [
                                    _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-bold" }, " Progreso de la semana y cercanía a la meta ", -1)),
                                    _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(update).goalProgress), 1)
                                ]),
                                _createElementVNode("div", _hoisted_13, [
                                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-bold" }, " Razón del avance ", -1)),
                                    _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(update).kpiVariationReason), 1)
                                ]),
                                _createElementVNode("div", _hoisted_15, [
                                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "font-bold" }, " Metas semanales ", -1)),
                                    _createElementVNode("span", null, _toDisplayString(_unref(update).weeklyGoals), 1)
                                ]),
                                _createElementVNode("div", _hoisted_16, [
                                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "font-bold" }, " Mayor obstáculo ", -1)),
                                    _createElementVNode("span", null, _toDisplayString(_unref(update).biggestObstacle), 1)
                                ]),
                                _createElementVNode("div", _hoisted_17, [
                                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "font-bold" }, " Aprendizajes de los usuarios ", -1)),
                                    _createElementVNode("span", null, _toDisplayString(_unref(update).learningsFromUsers), 1)
                                ]),
                                _createElementVNode("div", _hoisted_18, [
                                    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "font-bold" }, " Moral del equipo ", -1)),
                                    _createElementVNode("span", null, _toDisplayString(_unref(update).teamMorale), 1)
                                ]),
                                (_unref(update).interviewQuestions.length > 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                        _cache[10] || (_cache[10] = _createElementVNode("span", { class: "font-bold" }, " Preguntas para el Live Office Hour ", -1)),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(update).interviewQuestions, (question, index) => {
                                            return (_openBlock(), _createElementBlock("span", {
                                                key: index,
                                                class: "text-base"
                                            }, " - " + _toDisplayString(question), 1));
                                        }), 128))
                                    ]))
                                    : _createCommentVNode("", true)
                            ])
                        ]),
                        (_unref(update).isLastUpdate)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _cache[15] || (_cache[15] = _createElementVNode("h2", { class: "border-b-2 border-pv-yellow text-2xl" }, " Cierre ", -1)),
                                _createElementVNode("div", _hoisted_21, [
                                    _cache[12] || (_cache[12] = _createElementVNode("span", { class: "font-bold" }, " ¿Cumpliste la meta del Build Sprint? Si no la cumpliste, ¿por qué crees que fue? ", -1)),
                                    _createElementVNode("span", null, _toDisplayString(_unref(update).goalCompleted), 1)
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                    _cache[13] || (_cache[13] = _createElementVNode("span", { class: "font-bold" }, " Durante el Build Sprint, obtuviste algún beneficio importante para tu startup/proyecto que nos quieras contar? ", -1)),
                                    _createElementVNode("span", null, _toDisplayString(_unref(update).gotAnyBenefit), 1)
                                ]),
                                _createElementVNode("div", _hoisted_23, [
                                    _cache[14] || (_cache[14] = _createElementVNode("span", { class: "font-bold" }, " ¿Cómo evalúas tu experiencia en el Build Sprint? ", -1)),
                                    _createElementVNode("span", null, _toDisplayString(_unref(update).experienceRating), 1)
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        (buildSprintProject.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                _cache[18] || (_cache[18] = _createElementVNode("h2", { class: "border-b-2 border-pv-yellow text-2xl" }, " Proyecto ", -1)),
                                _createElementVNode("div", _hoisted_25, [
                                    _createElementVNode("div", _hoisted_26, [
                                        _cache[16] || (_cache[16] = _createElementVNode("span", { class: "font-bold" }, " Descripción proyecto ", -1)),
                                        _createElementVNode("span", null, _toDisplayString(buildSprintProject.value.shortDescription), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_27, [
                                        _cache[17] || (_cache[17] = _createElementVNode("span", { class: "font-bold" }, " Equipo ", -1)),
                                        _createElementVNode("span", null, _toDisplayString(buildSprintProject.value.team), 1)
                                    ])
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                        _cache[21] || (_cache[21] = _createElementVNode("h2", { class: "text-2xl font-bold" }, " Calificar ", -1)),
                        (buildSprintQualification.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                _createElementVNode("div", _hoisted_30, [
                                    _createElementVNode("span", _hoisted_31, _toDisplayString(buildSprintQualification.value.user.fullName), 1)
                                ]),
                                _createElementVNode("div", _hoisted_32, [
                                    _cache[19] || (_cache[19] = _createElementVNode("span", { class: "text-sm text-pv-gray" }, " Calificación ", -1)),
                                    _createElementVNode("span", _hoisted_33, _toDisplayString(buildSprintQualification.value.qualification), 1)
                                ]),
                                _createElementVNode("div", _hoisted_34, [
                                    _cache[20] || (_cache[20] = _createElementVNode("span", { class: "text-sm text-pv-gray" }, " Mensaje ", -1)),
                                    _createVNode(KalioRichText, {
                                        "rich-text": buildSprintQualification.value.message,
                                        class: "text-slate-50"
                                    }, null, 8, ["rich-text"])
                                ])
                            ]))
                            : _createCommentVNode("", true),
                        _createVNode(_component_vee_form, {
                            class: "flex flex-col gap-4",
                            onSubmit: onSubmit
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_kalio_select_input, {
                                    modelValue: form.value.qualification,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((form.value.qualification) = $event)),
                                    name: "qualification",
                                    rules: "required",
                                    label: "Calificación",
                                    description: "* No lo verán los evaluados.",
                                    options: qualificationOptions
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_kalio_long_text_input, {
                                    modelValue: form.value.message,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((form.value.message) = $event)),
                                    name: "message",
                                    rules: "required",
                                    label: "Mensaje de calificación",
                                    description: "* Sí lo verán los evaluados."
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_kalio_button, {
                                    label: "Enviar calificación",
                                    class: "w-full",
                                    type: "submit",
                                    loading: _unref(isLoading)
                                }, null, 8, ["loading"])
                            ]),
                            _: 1
                        })
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
