import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "flex gap-10"
};
const _hoisted_2 = { class: "flex w-2/3 flex-col" };
const _hoisted_3 = { class: "text-4xl font-bold" };
const _hoisted_4 = { class: "mt-8 flex flex-col gap-6" };
const _hoisted_5 = { class: "flex flex-col" };
const _hoisted_6 = { class: "flex flex-col" };
const _hoisted_7 = { class: "flex flex-col" };
const _hoisted_8 = { class: "flex w-1/3 flex-col gap-10" };
const _hoisted_9 = { class: "flex flex-col gap-4" };
const _hoisted_10 = {
    clas: "list-disc list-inside",
    class: "flex flex-col gap-2"
};
import { toRefs } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { projectsApi } from '@/build_sprint_pack/api/internal/build_sprint_projects';
export default /*@__PURE__*/ _defineComponent({
    __name: 'build-sprint-project-review',
    props: {
        projectId: {}
    },
    setup(__props) {
        const props = __props;
        const { projectId } = toRefs(props);
        const { data: project } = useQuery(['projects', projectId], () => projectsApi.show(projectId.value));
        return (_ctx, _cache) => {
            return (_unref(project))
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("h1", _hoisted_3, _toDisplayString(_unref(project).name), 1),
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-bold" }, " Meta del Build Sprint ", -1)),
                                _createElementVNode("span", null, _toDisplayString(_unref(project).goal), 1)
                            ]),
                            _createElementVNode("div", _hoisted_6, [
                                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-bold" }, " Descripción proyecto ", -1)),
                                _createElementVNode("span", null, _toDisplayString(_unref(project).shortDescription), 1)
                            ]),
                            _createElementVNode("div", _hoisted_7, [
                                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-bold" }, " Equipo ", -1)),
                                _createElementVNode("span", null, _toDisplayString(_unref(project).team), 1)
                            ])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-medium" }, " Miembros del equipo ", -1)),
                            _createElementVNode("ul", _hoisted_10, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(project).users, (user) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        key: user.id,
                                        class: "text-base"
                                    }, _toDisplayString(user.fullName), 1));
                                }), 128))
                            ])
                        ])
                    ])
                ]))
                : _createCommentVNode("", true);
        };
    }
});
