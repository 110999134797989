<script setup>
import { ref, watch } from 'vue';
import { useScroll, useThrottleFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import { useInvestorApplicationStore } from '../../stores';
import DemoDayInvestorApplicationListItem from './demo-day-investor-application-list-item.vue';

defineProps({
  loading: { type: Boolean, default: false },
  loadingBottom: { type: Boolean, default: false },
});
const emit = defineEmits(['set-application', 'scrolled-to-first', 'scrolled-to-last']);

const investorApplicationStore = useInvestorApplicationStore();
const {
  investorApplications,
  currentInvestorApplicationId,
} = storeToRefs(investorApplicationStore);

const scrollableElement = ref(null);
const { arrivedState } = useScroll(scrollableElement);

const THROTTLE_SCROLL_EVENT_TIME = 500;
const throttledScrollToLast = useThrottleFn(
  (state) => emit('scrolled-to-last', state),
  THROTTLE_SCROLL_EVENT_TIME,
);

watch(arrivedState, ({ bottom }) => {
  if (bottom) throttledScrollToLast('bottom');
});
</script>

<template>
  <div class="flex flex-col rounded-md border border-blue-gray-700 bg-pv-blue-900 p-4">
    <kalio-spinner
      v-if="loading"
      class="flex w-full justify-center"
    />
    <div
      v-else
      ref="scrollableElement"
      class="flex max-h-screen flex-col overflow-y-scroll bg-pv-blue-900"
    >
      <demo-day-investor-application-list-item
        v-for="(investorApplication) in investorApplications"
        :key="investorApplication.id"
        :application="investorApplication"
        :is-current="currentInvestorApplicationId === investorApplication.id"
        @set-application="$emit('set-application', investorApplication.id)"
      />
      <div
        v-if="loadingBottom"
        class="m-2 flex flex-col items-center p-2"
      >
        <kalio-spinner :size="8" />
      </div>
    </div>
  </div>
</template>
