import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex w-full max-w-lg flex-col space-y-12 sm:items-center" };
const _hoisted_2 = { class: "flex w-full flex-col space-y-4" };
const _hoisted_3 = { class: "flex flex-row items-center space-x-2" };
import { ref, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import usersApi from '@/api/internal/users';
export default /*@__PURE__*/ _defineComponent({ __name: 'invite-user-form', setup(__props) { const email = ref(undefined); const { data: users, error, isFetching, isSuccess, refetch } = useQuery({ queryKey: ['users', { emailEq: email }], queryFn: () => usersApi.getAll({ emailEq: email.value }), enabled: false }); watch(isSuccess, () => { const usersLength = users.value?.users.length; if (usersLength > 0) {
        window.location.href = `/internal/users/${users.value?.users[0].id}`;
    }
    else if (usersLength === 0) {
        window.location.href = `/internal/users/new?email=${email.value}`;
    } }); return (_ctx, _cache) => { const _component_kalio_alert = _resolveComponent("kalio-alert"); const _component_kalio_short_text_input = _resolveComponent("kalio-short-text-input"); const _component_kalio_button = _resolveComponent("kalio-button"); const _component_vee_form = _resolveComponent("vee-form"); const _directive_tooltip = _resolveDirective("tooltip"); return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-2xl font-medium" }, " Invitar usuario a Kalio ", -1)), _createElementVNode("div", _hoisted_2, [_unref(error) ? (_openBlock(), _createBlock(_component_kalio_alert, { key: 0, status: "alert", title: _unref(error) }, null, 8, ["title"])) : _createCommentVNode("", true), _createVNode(_component_vee_form, { class: "flex w-full flex-col space-y-8", as: "div" }, { default: _withCtx(({ handleSubmit }) => [_createVNode(_component_kalio_short_text_input, { modelValue: email.value, "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => email.value = $event), name: "email", rules: "required|email", label: "Email" }, null, 8, ["modelValue"]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(_component_kalio_button, { label: "Siguiente", class: "w-full sm:w-auto sm:px-12", loading: _unref(isFetching) || _unref(isSuccess), onClick: $event => handleSubmit(_unref(refetch)) }, null, 8, ["loading", "onClick"]), [[_directive_tooltip, 'Se verificará si el usuario existe en Kalio']])])]), _: 1 })])]); }; } });
