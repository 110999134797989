import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col space-y-4 rounded-lg bg-pv-blue-900 p-4" };
const _hoisted_2 = { class: "flex space-x-4" };
const _hoisted_3 = ["href"];
const _hoisted_4 = { class: "text-sm text-pv-gray" };
import ApplicationInterviewCard from '@/components/apply/application-interview-card.vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'startup-application-interviews', props: { interviews: {} }, setup(__props) { const props = __props; function interviewExtraText(interview) { if (interview.scheduleStatus === 'waiting_reschedule') {
        return 'Esperando reagendamiento 🕒';
    } if (!interview.decision)
        return ''; const decisionMap = { passed: 'Pasaron ✅', failed: 'No pasaron ❌', wait: 'Esperar 🕐' }; return decisionMap[interview.decision]; } return (_ctx, _cache) => { const _component_inline_svg = _resolveComponent("inline-svg"); return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-semibold" }, " Entrevistas ", -1)), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.interviews, interview => { return _openBlock(), _createElementBlock("div", { key: interview.id, class: "flex flex-row items-center gap-4" }, [_createElementVNode("a", { href: interview.path, target: "_blank" }, [_createVNode(ApplicationInterviewCard, { interview: interview, "interview-name": interview.kind === 'first_interview' ? 'Primera entrevista' : 'Segunda entrevista' }, { "day-extra-element": _withCtx(() => [_createElementVNode("div", { class: _normalizeClass(["absolute -right-1 -top-1 rounded-full border border-blue-gray-200 p-1", !!interview.decision ? 'bg-green-400' : 'bg-pv-light-blue animate-ping']) }, [!!interview.decision ? (_openBlock(), _createBlock(_component_inline_svg, { key: 0, src: require('assets/images/icons/check.svg'), class: "size-2 fill-current text-blue-gray-50" }, null, 8, ["src"])) : _createCommentVNode("", true)], 2)]), "extra-info": _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString(interviewExtraText(interview)), 1)]), _: 2 }, 1032, ["interview", "interview-name"])], 8, _hoisted_3)]); }), 128))])]); }; } });
