import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "overflow-hidden rounded-lg bg-pv-blue-900" };
const _hoisted_2 = { key: 0, class: "flex justify-center p-4" };
const _hoisted_3 = { key: 2, class: "relative w-full overflow-hidden rounded-lg pt-[56.25%]" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { key: 3, class: "my-2 flex justify-center" };
const _hoisted_6 = ["href", "download"];
import { computed } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import useFormAnswers from '@/hooks/form_answers.js';
import startupApplicationApi from '@/api/startup_application.js';
import BaseHlsPlayer from '@/components/shared/base-hls-player.vue';
import { getVideoEmbedUrl } from '@/utils/review.js';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'startup-application-recorded-interview', props: { startupApplication: {} }, setup(__props) { const props = __props; const { getAnswer } = useFormAnswers(['founders.video'], props.startupApplication); const videoPresentation = computed(() => getAnswer('founders.video')); const { data: recordedInterview, isLoading } = useQuery(['recordedInterview', props.startupApplication], () => startupApplicationApi.getRecordedInterview(props.startupApplication.id).then(response => response.recordedInterview)); const recordedInterviewMarkers = computed(() => recordedInterview.value.recordedInterviewQuestions.map(question => ({ time: question.start, description: question.question }))); return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(isLoading) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(KalioSpinner)])) : _createCommentVNode("", true), _unref(recordedInterview)?.dailyRecordingHlsUrl ? (_openBlock(), _createBlock(BaseHlsPlayer, { key: 1, "video-url": _unref(recordedInterview).dailyRecordingHlsUrl, markers: recordedInterviewMarkers.value, "picture-in-picture": "" }, null, 8, ["video-url", "markers"])) : _createCommentVNode("", true), videoPresentation.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("iframe", { class: "absolute inset-0 size-full", src: _unref(getVideoEmbedUrl)(videoPresentation.value), frameborder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowfullscreen: "" }, null, 8, _hoisted_4)])) : _createCommentVNode("", true), _unref(recordedInterview) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("a", { href: _unref(recordedInterview)?.recordingDownloadUrl, download: `entrevista-${_ctx.startupApplication.startupName}.mp4` }, " Descargar MP4 ", 8, _hoisted_6)])) : _createCommentVNode("", true)]); }; } });
