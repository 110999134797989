import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-4 pl-2" };
const _hoisted_2 = { class: "flex flex-col gap-2" };
const _hoisted_3 = { key: 0, class: "flex flex-col" };
const _hoisted_4 = { class: "font-bold" };
const _hoisted_5 = { key: 1, class: "flex flex-col" };
const _hoisted_6 = { class: "font-bold" };
import { format } from 'date-fns';
import BaseWeeklyUpdateCard from '@/components/weekly-updates/base-card.vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'company-info-metrics', props: { programGoal: {}, lastWeeklyUpdate: {}, secondToLastWeeklyUpdate: {} }, setup(__props) { const props = __props; return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [props.lastWeeklyUpdate ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, " Semana pasada (" + _toDisplayString(_unref(format)(props.lastWeeklyUpdate.startDate, 'd/MM')) + ") ", 1), _createVNode(BaseWeeklyUpdateCard, { "weekly-update": props.lastWeeklyUpdate, "program-goal": props.programGoal }, null, 8, ["weekly-update", "program-goal"])])) : _createCommentVNode("", true), props.secondToLastWeeklyUpdate ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, " Semana antepasada (" + _toDisplayString(_unref(format)(props.secondToLastWeeklyUpdate.startDate, 'd/MM')) + ") ", 1), _createVNode(BaseWeeklyUpdateCard, { "weekly-update": props.secondToLastWeeklyUpdate, "program-goal": props.programGoal }, null, 8, ["weekly-update", "program-goal"])])) : _createCommentVNode("", true)])]); }; } });
