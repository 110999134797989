import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "h-144 overflow-scroll sm:h-144" };
const _hoisted_2 = ["data-url"];
import { onMounted, computed, inject } from 'vue';
import { currentUserKey } from '@/utils/keys.js';
export default /*@__PURE__*/ _defineComponent({ __name: 'kalio-platanus-cal-office-hour', props: { platanusCalUrl: {}, officeHourRequestId: {}, subject: { default: '' } }, setup(__props) {
        const currentUser = inject(currentUserKey);
        const props = __props;
        const officeHourRequestId = computed(() => props.officeHourRequestId?.toString());
        const fullUrl = computed(() => { if (officeHourRequestId.value === undefined)
            return ''; const url = new URL(props.platanusCalUrl); const params = new URLSearchParams(); params.append('name', currentUser.value.fullName || ''); params.append('email', currentUser.value.email); params.append('notes', props.subject); params.append('metadata[officeHourRequestId]', officeHourRequestId.value); url.search = params.toString().replace(/\+/g, '%20'); return url.toString(); });
        onMounted(() => {
            const calendlyScript = document.createElement('script');
            calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
            calendlyScript.setAttribute('async', 'true');
            document.head.appendChild(calendlyScript);
        });
        return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", { class: "calendly-inline-widget size-full", "data-url": fullUrl.value }, null, 8, _hoisted_2)]); };
    } });
