import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = ["value", "name"];
import { watch, computed, toRefs } from 'vue';
import { useField } from 'vee-validate';
import KalioBaseInput from './kalio-base-input.vue';
import KalioTextAnswer from './kalio-text-answer.vue';
export default /*@__PURE__*/ _defineComponent({ ...{ inheritAttrs: false }, __name: 'kalio-short-text-input', props: { name: {}, rules: { default: '' }, modelValue: { default: '' }, value: { default: null }, label: { default: '' }, iconName: { default: null }, iconClasses: { default: null }, description: { default: '' }, error: { default: null }, variant: { default: 'dark' }, size: { default: 'base' }, absoluteError: { type: Boolean, default: false }, displayError: { type: Boolean, default: true }, onlyAnswer: { type: Boolean, default: false }, answerVariant: { default: 'semi-dark' } }, emits: ['update:modelValue'], setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const { name, rules } = toRefs(props);
        const { value: inputValue, errorMessage, validate, handleChange } = useField(name, rules, { initialValue: props.modelValue || props.value });
        watch(() => props.modelValue, newModel => { if (newModel !== inputValue.value) {
            inputValue.value = newModel;
        } });
        const required = computed(() => props.rules.includes('required'));
        const inputClasses = computed(() => { const classes = { dark: 'bg-pv-blue-900', 'semi-dark': 'bg-pv-blue-700' }; return classes[props.variant]; });
        const containerClasses = computed(() => { const colorClasses = { dark: 'bg-pv-blue-900', 'semi-dark': 'bg-pv-blue-700' }; const sizeClasses = { base: 'py-2.5 pl-3 pr-6 sm:pt-2', small: 'py-1 pl-2 pr-6 sm:pt-1 text-sm' }; return `${colorClasses[props.variant]} ${sizeClasses[props.size]}`; });
        function hasProtocol(value) { return value?.indexOf('https://') !== -1 || value?.indexOf('http://') !== -1; }
        watch(inputValue, (newVal, previousVal) => { if (props.rules.includes('url')) {
            if (newVal?.length > (previousVal || '')?.length && !hasProtocol(newVal)) {
                handleChange(`https://${newVal}`);
            }
        } emit('update:modelValue', inputValue.value); });
        return (_ctx, _cache) => {
            const _component_inline_svg = _resolveComponent("inline-svg");
            return props.onlyAnswer ? (_openBlock(), _createBlock(KalioTextAnswer, { key: 0, label: props.label, variant: props.answerVariant, answer: props.modelValue?.toString() }, null, 8, ["label", "variant", "answer"])) : (_openBlock(), _createBlock(KalioBaseInput, { key: 1, label: _ctx.label, description: _ctx.description, required: required.value, size: _ctx.size, class: _normalizeClass({ 'hidden': _ctx.$attrs.class && _ctx.$attrs.class.includes('hidden') }) }, { input: _withCtx(() => [_createElementVNode("div", { class: _normalizeClass(["flex flex-col", { 'relative': _ctx.absoluteError }]) }, [_createElementVNode("div", { class: _normalizeClass(["flex w-full appearance-none flex-row items-center justify-between space-x-3 rounded-md border-2", [_unref(errorMessage) || !!_ctx.error ? 'border-red-600' : 'border-blue-gray-700 focus-within:border-pv-light-blue focus-within:ring-pv-light-blue', containerClasses.value]]) }, [_createElementVNode("input", _mergeProps(_ctx.$attrs, { value: _unref(inputValue), class: ["w-full outline-none", inputClasses.value], name: _unref(name), onBlur: _cache[0] || (_cache[0] = //@ts-ignore
                                    (...args) => _unref(validate) && _unref(validate)(...args)), onInput: _cache[1] || (_cache[1] = //@ts-ignore
                                    (...args) => _unref(handleChange) && _unref(handleChange)(...args)) }), null, 16, _hoisted_1), _ctx.iconName ? (_openBlock(), _createBlock(_component_inline_svg, { key: 0, src: require(`assets/images/icons/${_ctx.iconName}`), class: _normalizeClass(_ctx.iconClasses) }, null, 8, ["src", "class"])) : _createCommentVNode("", true)], 2), (_unref(errorMessage) || _ctx.error) && _ctx.displayError ? (_openBlock(), _createElementBlock("span", { key: 0, class: _normalizeClass(["text-sm text-red-600", _ctx.absoluteError ? 'absolute -bottom-6 left-0' : 'mt-0.5']) }, _toDisplayString(_unref(errorMessage) || _ctx.error), 3)) : _createCommentVNode("", true)], 2)]), _: 1 }, 8, ["label", "description", "required", "size", "class"]));
        };
    } });
