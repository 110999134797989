import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "flex w-full flex-col overflow-hidden rounded-xl border border-blue-gray-700 bg-pv-blue-800 shadow-lg" };
const _hoisted_2 = { class: "flex flex-row items-center gap-3 px-6 py-3" };
const _hoisted_3 = ["value", "disabled", "placeholder"];
const _hoisted_4 = { key: 0, class: "border-t border-blue-gray-700 bg-pv-blue-900 px-6 py-2" };
import { ref, watch, onMounted } from 'vue';
import KalioSpinner from './kalio-spinner.vue';
const REFOCUS_DELAY = 50;
export default /*@__PURE__*/ _defineComponent({ __name: 'kalio-command-bar', props: { placeholder: { default: 'Buscar' }, loading: { type: Boolean, default: false }, visibleSlot: { type: Boolean, default: true }, modelValue: { default: '' } }, emits: ["search", "close", "update:modelValue"], setup(__props, { emit: __emit }) { const emit = __emit; const props = __props; const inputRef = ref(null); function focus() { if (inputRef?.value)
        inputRef.value.focus(); } onMounted(focus); watch(() => props.loading, newVal => { if (newVal)
        return; setTimeout(focus, REFOCUS_DELAY); }); return (_ctx, _cache) => { const _component_inline_svg = _resolveComponent("inline-svg"); const _directive_click_outside = _resolveDirective("click-outside"); return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_inline_svg, { src: require('assets/images/icons/search.svg'), class: "size-4 fill-current text-pv-gray" }, null, 8, ["src"]), _createElementVNode("input", { ref_key: "inputRef", ref: inputRef, value: _ctx.modelValue, disabled: _ctx.loading, class: "w-full bg-transparent text-pv-gray outline-none", placeholder: _ctx.placeholder, onInput: _cache[0] || (_cache[0] = evt => evt?.target && emit('update:modelValue', evt.target.value)), onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers($event => emit('close'), ["prevent"]), ["esc"])), onKeypress: _cache[2] || (_cache[2] = _withKeys(_withModifiers($event => emit('search'), ["exact", "prevent"]), ["enter"])) }, null, 40, _hoisted_3), _ctx.loading ? (_openBlock(), _createBlock(KalioSpinner, { key: 0, size: 4 })) : _createCommentVNode("", true)]), _ctx.visibleSlot ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "default")])) : _createCommentVNode("", true)])), [[_directive_click_outside, () => emit('close')]]); }; } });
