import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "w-full max-w-5xl" };
const _hoisted_2 = { class: "mb-10 text-center text-xl text-slate-300" };
const _hoisted_3 = { class: "flex w-full flex-col divide-y divide-slate-700" };
const _hoisted_4 = { class: "w-1/3 px-5 py-2" };
const _hoisted_5 = { class: "w-1/3 px-5 py-2" };
const _hoisted_6 = {
    key: 0,
    class: "rounded-full border border-emerald-300 bg-emerald-300/30 px-2 py-1 text-sm text-emerald-300"
};
const _hoisted_7 = {
    key: 1,
    class: "rounded-full border border-red-400 bg-red-400/30 px-2 py-1 text-sm text-red-400"
};
const _hoisted_8 = { class: "flex w-1/3 gap-2 px-5 py-2" };
const _hoisted_9 = ["href"];
const _hoisted_10 = ["href"];
const _hoisted_11 = ["href"];
import { computed } from 'vue';
import { eachMonthOfInterval, formatISO, parseISO, subMonths } from 'date-fns';
import { capitalize } from '@/filters';
export default /*@__PURE__*/ _defineComponent({
    __name: 'company-updates-list',
    props: {
        company: {},
        companyUpdates: {}
    },
    setup(__props) {
        const props = __props;
        const interval = {
            start: parseISO(props.company.trackingStartedAt || ''),
            end: subMonths(new Date(), 1),
        };
        const months = eachMonthOfInterval(interval)
            .reverse()
            .map((month) => formatISO(month, { representation: 'date' }));
        const companyUpdateItems = computed(() => months.map((month) => {
            const companyUpdate = props.companyUpdates.find((update) => update.month === month);
            const formattedMonth = capitalize(parseISO(month).toLocaleDateString('es', { month: 'long', year: 'numeric' }));
            const isCompleted = !!companyUpdate;
            const urls = {};
            if (isCompleted) {
                urls.show = `/tracking/company_updates/${companyUpdate.id}`;
                urls.edit = `/tracking/company_updates/${companyUpdate.id}/edit`;
            }
            else {
                urls.new = `/tracking/company_updates/new?month=${month}`;
            }
            return {
                formattedMonth,
                isCompleted,
                urls,
            };
        }));
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "mb-5 text-center text-4xl font-medium text-slate-50" }, " Actualizaciones mensuales ", -1)),
                _createElementVNode("h2", _hoisted_2, _toDisplayString(props.company.name), 1),
                _createElementVNode("div", _hoisted_3, [
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex rounded-t-md bg-slate-700 font-medium text-slate-50" }, [
                        _createElementVNode("p", { class: "w-1/3 px-5 py-2" }, " Mes "),
                        _createElementVNode("p", { class: "w-1/3 px-5 py-2" }, " Estado "),
                        _createElementVNode("p", { class: "w-1/3 px-5 py-2" })
                    ], -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companyUpdateItems.value, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: item.formattedMonth,
                            class: "flex items-center bg-pv-blue-900 last:rounded-b-md"
                        }, [
                            _createElementVNode("div", _hoisted_4, _toDisplayString(item.formattedMonth), 1),
                            _createElementVNode("div", _hoisted_5, [
                                (item.isCompleted)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, " Completo "))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_7, " Incompleto "))
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                                (item.urls.new)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        href: item.urls.new,
                                        class: "text-sm text-pv-yellow underline underline-offset-2"
                                    }, " Completar ", 8, _hoisted_9))
                                    : _createCommentVNode("", true),
                                (item.urls.show)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 1,
                                        href: item.urls.show,
                                        class: "text-sm text-pv-yellow underline underline-offset-2"
                                    }, " Ver ", 8, _hoisted_10))
                                    : _createCommentVNode("", true),
                                (item.urls.edit)
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 2,
                                        href: item.urls.edit,
                                        class: "text-sm text-pv-yellow underline underline-offset-2"
                                    }, " Editar ", 8, _hoisted_11))
                                    : _createCommentVNode("", true)
                            ])
                        ]));
                    }), 128))
                ])
            ]));
        };
    }
});
