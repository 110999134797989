import humps from 'humps';
import api from './api';

export default {
  create(startupApplication) {
    const path = '/api/v1/startup_applications/';

    return api({
      method: 'post',
      data: humps.decamelizeKeys({ startupApplication }),
      url: path,
    });
  },
  update(id, startupApplication) {
    const path = `/api/v1/startup_applications/${id}`;

    return api({
      method: 'put',
      data: humps.decamelizeKeys({ startupApplication }),
      url: path,
    });
  },
  tagsUpdate(id, tags) {
    const path = `/internal/api/v1/startup_applications/${id}/tags`;

    return api({
      method: 'put',
      data: humps.decamelizeKeys({ id, tags }),
      url: path,
    });
  },
  get(id) {
    const path = `/internal/api/v1/startup_applications/${id}`;

    return api({
      method: 'get',
      url: path,
    });
  },
  search(query) {
    const path = '/internal/api/v1/startup_applications/search';

    return api({
      method: 'get',
      params: { query },
      url: path,
    });
  },
  sendDiscardEmail(id, data) {
    const path = `/internal/api/v1/startup_applications/${id}/send_discard_email`;
    const { template, feedback, partnerId } = data;

    return api({
      method: 'put',
      params: humps.decamelizeKeys({ template, feedback, partnerId }),
      url: path,
    });
  },
  sendEvent(id, event) {
    const path = `/internal/api/v1/startup_applications/${id}/state_event`;

    return api({
      method: 'put',
      data: humps.decamelizeKeys({ id, event }),
      url: path,
    });
  },
  cofoundersInvited() {
    const path = '/apply/cofounders_invited';

    return api({
      method: 'patch',
      url: path,
    });
  },
  getRecordedInterview(id) {
    const path = `/internal/api/v1/startup_applications/${id}/recorded_interview`;

    return api({
      method: 'get',
      url: path,
    });
  },
};
