import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex w-full gap-10 p-4" };
const _hoisted_2 = { class: "flex w-1/4 flex-col" };
const _hoisted_3 = {
    key: 0,
    class: "mt-4 flex flex-col"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
    key: 1,
    class: "mt-4 p-4 text-sm text-pv-gray"
};
const _hoisted_6 = {
    key: 1,
    class: "w-3/4 p-4 text-sm text-pv-gray"
};
import { ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { projectsApi } from '@/build_sprint_pack/api/internal/build_sprint_projects';
import { useUserStore } from '@/stores';
import KalioBuildSprintProjectReview from './build-sprint-project-review.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'build-sprint-projects-review',
    props: {
        projects: {},
        reviewers: {}
    },
    setup(__props) {
        const props = __props;
        const filterOptions = {
            reviewerIdIn: props.reviewers.map((reviewer) => ({
                value: reviewer.id,
                label: reviewer.fullName,
            })),
        };
        const userStore = useUserStore();
        const selectedFilters = ref({
            reviewerIdIn: userStore.currentUser ? [userStore.currentUser.id] : [],
        });
        const { data: filteredProjects } = useQuery(['updates', selectedFilters], () => projectsApi.index(selectedFilters.value), {
            initialData: props.projects,
            refetchOnMount: false,
        });
        const selectedProjectId = ref(props.projects[0]?.id);
        return (_ctx, _cache) => {
            const _component_kalio_select_input = _resolveComponent("kalio-select-input");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_kalio_select_input, {
                        modelValue: selectedFilters.value.reviewerIdIn,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((selectedFilters.value.reviewerIdIn) = $event)),
                        name: "reviewerIdIn",
                        label: "Encargado",
                        reduce: (option) => option.value,
                        options: filterOptions.reviewerIdIn,
                        multiple: ""
                    }, null, 8, ["modelValue", "reduce", "options"]),
                    (_unref(filteredProjects).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredProjects), (project) => {
                                return (_openBlock(), _createElementBlock("button", {
                                    key: project.id,
                                    class: _normalizeClass(["rounded-lg p-4 text-left text-sm text-pv-gray", { 'bg-pv-blue-800': project.id === selectedProjectId.value }]),
                                    onClick: ($event) => (selectedProjectId.value = project.id)
                                }, _toDisplayString(project.name), 11, _hoisted_4));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_5, " No se encontraron proyectos activos. "))
                ]),
                _createVNode(_Transition, {
                    "enter-active-class": "duration-200 ease-in",
                    "enter-from-class": "transform opacity-0",
                    "enter-to-class": "opacity-100",
                    "leave-active-class": "duration-200 ease-out",
                    "leave-from-class": "opacity-100",
                    "leave-to-class": "transform opacity-0"
                }, {
                    default: _withCtx(() => [
                        (selectedProjectId.value)
                            ? (_openBlock(), _createBlock(KalioBuildSprintProjectReview, {
                                key: 0,
                                "project-id": selectedProjectId.value,
                                class: "w-3/4"
                            }, null, 8, ["project-id"]))
                            : (_openBlock(), _createElementBlock("p", _hoisted_6, " Selecciona un proyecto para ver su detalle. "))
                    ]),
                    _: 1
                })
            ]));
        };
    }
});
