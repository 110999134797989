import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex w-full flex-row gap-4" };
const _hoisted_2 = { class: "relative flex h-16 w-20 shrink-0 flex-col items-center justify-center rounded-lg border border-blue-gray-600 bg-pv-blue-700" };
const _hoisted_3 = { class: "flex grow flex-col" };
const _hoisted_4 = { class: "text-base font-medium" };
const _hoisted_5 = { class: "text-sm font-light text-pv-yellow" };
import { computed } from 'vue';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { capitalize } from '@/filters';
export default /*@__PURE__*/ _defineComponent({ __name: 'application-interview-card', props: { interview: {}, interviewName: { default: undefined }, durationMinutes: { default: 15 } }, setup(__props) { const props = __props; const interviewDate = computed(() => new Date(props.interview.startDate)); const interviewTypeName = computed(() => { const prefix = props.interview.kind === 'first_interview' ? 'Primera' : 'Segunda'; return `${prefix} entrevista con el equipo de Platanus`; }); const isWaitingReschedule = computed(() => props.interview.scheduleStatus === 'waiting_reschedule'); return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", { class: _normalizeClass(["text-base font-thin md:text-lg", { 'line-through': isWaitingReschedule.value }]) }, _toDisplayString(_unref(capitalize)(_unref(format)(interviewDate.value, 'MMM', { locale: _unref(es) }))), 3), _createElementVNode("span", { class: _normalizeClass(["text-lg font-semibold md:text-xl", { 'line-through': isWaitingReschedule.value }]) }, _toDisplayString(_unref(format)(interviewDate.value, 'dd')), 3), _renderSlot(_ctx.$slots, "day-extra-element")]), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString(props.interviewName ? props.interviewName : interviewTypeName.value), 1), _createElementVNode("span", { class: _normalizeClass(["text-sm text-pv-gray", { 'line-through': isWaitingReschedule.value }]) }, _toDisplayString(_unref(format)(interviewDate.value, 'HH:mm a')) + " (GMT" + _toDisplayString(_unref(format)(interviewDate.value, 'xxx')) + ") ", 3), _renderSlot(_ctx.$slots, "extra-info", {}, () => [_createElementVNode("span", _hoisted_5, " Duración: " + _toDisplayString(props.durationMinutes) + " Minutos ", 1)])])]); }; } });
