import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "text-lg font-bold" };
const _hoisted_2 = { class: "my-2 flex flex-col gap-3" };
const _hoisted_3 = { class: "mt-4 flex justify-center" };
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import KalioFormWrapper from '@/components/shared/kalio-form-wrapper.vue';
import KalioShortTextInput from '@/components/form/kalio-short-text-input.vue';
import KalioTelInput from '@/components/form/kalio-tel-input.vue';
import useConnectionsStore from '@/demo_day_pack/stores/connections';
export default /*@__PURE__*/ _defineComponent({
    __name: 'demo-day-connect-investor-form',
    emits: ["create"],
    setup(__props, { emit: __emit }) {
        const emit = __emit;
        const { t } = useI18n();
        const { setInvestorInfo } = useConnectionsStore();
        const isLoading = ref(false);
        const currentInvestorInfo = ref({
            investorName: '',
            investorCompany: '',
            investorEmail: '',
            investorPhone: '',
        });
        function onSubmit() {
            if (!currentInvestorInfo.value)
                return;
            isLoading.value = true;
            setInvestorInfo(currentInvestorInfo.value);
            setTimeout(() => {
                isLoading.value = false;
                emit('create', currentInvestorInfo.value);
                // eslint-disable-next-line no-magic-numbers
            }, 1000);
        }
        return (_ctx, _cache) => {
            const _component_kalio_button = _resolveComponent("kalio-button");
            return (_openBlock(), _createBlock(KalioFormWrapper, { onSubmit: onSubmit }, {
                default: _withCtx(() => [
                    _createElementVNode("h1", _hoisted_1, _toDisplayString(_unref(t)('demoDay.connections.investorForm.title')), 1),
                    _createElementVNode("div", _hoisted_2, [
                        _createVNode(KalioShortTextInput, {
                            modelValue: currentInvestorInfo.value.investorName,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((currentInvestorInfo.value.investorName) = $event)),
                            name: "investor_name",
                            placeholder: "Peter Thiel",
                            label: _unref(t)('demoDay.connections.investorForm.investorName'),
                            rules: "required"
                        }, null, 8, ["modelValue", "label"]),
                        _createVNode(KalioShortTextInput, {
                            modelValue: currentInvestorInfo.value.investorCompany,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((currentInvestorInfo.value.investorCompany) = $event)),
                            name: "investor_company",
                            placeholder: "Founders Fund",
                            description: _unref(t)('demoDay.connections.investorForm.investorCompanyDescription'),
                            label: _unref(t)('demoDay.connections.investorForm.investorCompany')
                        }, null, 8, ["modelValue", "description", "label"]),
                        _createVNode(KalioShortTextInput, {
                            modelValue: currentInvestorInfo.value.investorEmail,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((currentInvestorInfo.value.investorEmail) = $event)),
                            name: "investor_email",
                            placeholder: "peter@foundersfund.com",
                            label: "Email",
                            rules: "required|email"
                        }, null, 8, ["modelValue"]),
                        _createVNode(KalioTelInput, {
                            modelValue: currentInvestorInfo.value.investorPhone,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((currentInvestorInfo.value.investorPhone) = $event)),
                            "country-hint": { code: 'MX' },
                            name: "investor_phone",
                            label: _unref(t)('demoDay.connections.investorForm.investorPhone'),
                            required: ""
                        }, null, 8, ["modelValue", "label"])
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_kalio_button, {
                            loading: isLoading.value,
                            type: "submit",
                            label: _unref(t)('demoDay.connections.investorForm.submit')
                        }, null, 8, ["loading", "label"])
                    ])
                ]),
                _: 1
            }));
        };
    }
});
