import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "absolute right-0 top-0 rounded-bl-lg bg-green-600 p-[2px]"
};
const _hoisted_2 = { class: "flex gap-2 text-left" };
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = { class: "flex flex-col justify-start" };
const _hoisted_5 = { class: "text-lg font-bold" };
const _hoisted_6 = { class: "text-sm text-blue-gray-300" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'demo-day-connect-company-card',
    props: {
        demoDayCompany: {},
        borderClass: { default: 'border rounded-lg border-blue-gray-600' },
        isConnected: { type: Boolean, default: false }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            const _component_inline_svg = _resolveComponent("inline-svg");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(`w-full relative flex items-center p-1 ${props.borderClass} overflow-hidden`)
            }, [
                (props.isConnected)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createVNode(_component_inline_svg, {
                            src: require('assets/images/icons/check.svg'),
                            class: "size-5 fill-current text-white"
                        }, null, 8, ["src"])
                    ]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                        src: props.demoDayCompany.company.isotypeUrl,
                        alt: `${props.demoDayCompany.company.name} logo`,
                        class: "size-20 rounded-lg object-contain"
                    }, null, 8, _hoisted_3),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("h3", _hoisted_5, _toDisplayString(props.demoDayCompany.company.name), 1),
                        _createElementVNode("p", _hoisted_6, _toDisplayString(props.demoDayCompany.oneLiner), 1)
                    ])
                ])
            ], 2));
        };
    }
});
