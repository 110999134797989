import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-2" };
const _hoisted_2 = { class: "flex flex-col gap-4 pl-2" };
const _hoisted_3 = { key: 0, class: "flex flex-col gap-2" };
const _hoisted_4 = { class: "font-bold" };
const _hoisted_5 = { class: "blockquote italic" };
const _hoisted_6 = { key: 1, class: "flex flex-col gap-2" };
const _hoisted_7 = { class: "font-bold" };
const _hoisted_8 = { class: "blockquote italic" };
import { format } from 'date-fns';
export default /*@__PURE__*/ _defineComponent({ __name: 'company-info-help', props: { lastWeeklyUpdate: {}, secondToLastWeeklyUpdate: {} }, setup(__props) { const props = __props; return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-xs font-light" }, " Los fundadores pidieron ayuda con los siguientes temas ", -1)), _createElementVNode("div", _hoisted_2, [props.lastWeeklyUpdate ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, " Semana pasada (" + _toDisplayString(_unref(format)(props.lastWeeklyUpdate.startDate, 'd/MM')) + ") ", 1), _createElementVNode("p", _hoisted_5, _toDisplayString(props.lastWeeklyUpdate.helpAsks), 1)])) : _createCommentVNode("", true), props.secondToLastWeeklyUpdate ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, " Semana antepasada (" + _toDisplayString(_unref(format)(props.secondToLastWeeklyUpdate.startDate, 'd/MM')) + ") ", 1), _createElementVNode("p", _hoisted_8, _toDisplayString(props.secondToLastWeeklyUpdate.helpAsks), 1)])) : _createCommentVNode("", true)])]); }; } });
