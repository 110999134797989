import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, unref as _unref, resolveComponent as _resolveComponent } from "vue";
const _hoisted_1 = { class: "flex flex-col items-center gap-6 rounded-lg border-2 border-blue-gray-600 bg-pv-blue-700 px-4 pb-6 pt-12" };
const _hoisted_2 = { class: "flex w-full flex-col gap-3" };
const _hoisted_3 = { class: "text-justify text-sm text-blue-gray-300" };
import { useMutation } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import KalioModal from '@/components/shared/kalio-modal.vue';
import { connectionsApi } from '@/demo_day_pack/api/connections';
import useConnectionsStore from '@/demo_day_pack/stores/connections';
import DemoDayConnectCompanyCard from '@/demo_day_pack/components/demo-day-connect-company-card.vue';
import DemoDayConnectFounderCard from '@/demo_day_pack/components/demo-day-connect-founder-card.vue';
import KalioExpandableContent from '@/components/shared/kalio-expandable-content.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'demo-day-connect-modal',
    props: {
        demoDayCompany: {},
        isConnected: { type: Boolean, default: false }
    },
    emits: ["close", "connect"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const { investorInfo } = storeToRefs(useConnectionsStore());
        const { t } = useI18n();
        const { mutate: createConnection, isLoading } = useMutation(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        () => connectionsApi.create({ ...investorInfo.value, demoDayCompanyId: props.demoDayCompany.id }), {
            onSuccess: (connection) => {
                emit('connect', connection);
            },
        });
        return (_ctx, _cache) => {
            const _component_kalio_button = _resolveComponent("kalio-button");
            return (_openBlock(), _createBlock(KalioModal, {
                onClose: _cache[0] || (_cache[0] = ($event) => (emit('close')))
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(DemoDayConnectCompanyCard, {
                            "demo-day-company": props.demoDayCompany
                        }, null, 8, ["demo-day-company"]),
                        _createElementVNode("div", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.demoDayCompany.company.users, (founder) => {
                                return (_openBlock(), _createBlock(DemoDayConnectFounderCard, {
                                    key: founder.id,
                                    founder: founder
                                }, null, 8, ["founder"]));
                            }), 128))
                        ]),
                        _createVNode(KalioExpandableContent, {
                            "max-height": "120px",
                            "background-class": "bg-pv-blue-700"
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_3, _toDisplayString(props.demoDayCompany.blurb), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_kalio_button, {
                            label: props.isConnected ? _unref(t)('demoDay.connections.alreadyConnected') : _unref(t)('demoDay.connections.connect'),
                            loading: _unref(isLoading),
                            disabled: props.isConnected,
                            onClick: _unref(createConnection)
                        }, null, 8, ["label", "loading", "disabled", "onClick"])
                    ])
                ]),
                _: 1
            }));
        };
    }
});
