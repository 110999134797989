import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "grid w-full grid-cols-4 gap-10" };
const _hoisted_2 = { class: "flex flex-col gap-4" };
const _hoisted_3 = {
    key: 0,
    class: "mt-4 flex flex-col overflow-y-scroll"
};
const _hoisted_4 = ["onClick"];
const _hoisted_5 = {
    key: 1,
    class: "mt-4 p-4 text-sm text-pv-gray"
};
const _hoisted_6 = {
    key: 1,
    class: "col-span-3 p-8 text-center text-sm text-pv-gray"
};
import { ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { useUserStore } from '@/stores';
import { updatesApi } from '@/build_sprint_pack/api/internal/build_sprint_updates';
import BuildSprintUpdateReview from './build-sprint-update-review.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'build-sprint-updates-review',
    props: {
        updates: {},
        reviewers: {}
    },
    setup(__props) {
        const props = __props;
        const filterOptions = {
            buildSprintProjectReviewerIdIn: props.reviewers.map((reviewer) => ({
                value: reviewer.id,
                label: reviewer.fullName,
            })),
            buildSprintQualificationIdNull: [
                { value: true, label: 'No' },
                { value: false, label: 'Sí' },
            ],
            buildSprintQualificationQualificationIn: ['awful', 'not_good', 'average', 'great'],
        };
        const userStore = useUserStore();
        const selectedFilters = ref({
            buildSprintProjectReviewerIdIn: userStore.currentUser ? [userStore.currentUser.id] : [],
            buildSprintQualificationIdNull: true,
            buildSprintQualificationQualificationIn: [],
        });
        const { data: filteredUpdates } = useQuery(['updates', selectedFilters], () => updatesApi.index(selectedFilters.value), {
            initialData: props.updates,
            refetchOnMount: false,
        });
        const selectedUpdateId = ref(props.updates[0]?.id);
        return (_ctx, _cache) => {
            const _component_kalio_select_input = _resolveComponent("kalio-select-input");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_kalio_select_input, {
                        modelValue: selectedFilters.value.buildSprintProjectReviewerIdIn,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((selectedFilters.value.buildSprintProjectReviewerIdIn) = $event)),
                        name: "buildSprintProjectReviewerIdIn",
                        label: "Encargado",
                        reduce: (option) => option.value,
                        options: filterOptions.buildSprintProjectReviewerIdIn,
                        multiple: ""
                    }, null, 8, ["modelValue", "reduce", "options"]),
                    _createVNode(_component_kalio_select_input, {
                        modelValue: selectedFilters.value.buildSprintQualificationIdNull,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((selectedFilters.value.buildSprintQualificationIdNull) = $event)),
                        name: "buildSprintQualificationIdNull",
                        label: "¿Calificado?",
                        clearable: false,
                        reduce: (option) => option.value,
                        options: filterOptions.buildSprintQualificationIdNull
                    }, null, 8, ["modelValue", "reduce", "options"]),
                    _createVNode(_component_kalio_select_input, {
                        modelValue: selectedFilters.value.buildSprintQualificationQualificationIn,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((selectedFilters.value.buildSprintQualificationQualificationIn) = $event)),
                        name: "buildSprintQualificationQualificationIn",
                        label: "Calificación",
                        options: filterOptions.buildSprintQualificationQualificationIn,
                        multiple: ""
                    }, null, 8, ["modelValue", "options"]),
                    (_unref(filteredUpdates).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredUpdates), (update) => {
                                return (_openBlock(), _createElementBlock("button", {
                                    key: update.id,
                                    class: _normalizeClass(["rounded-lg p-4 text-left text-sm text-pv-gray", { 'bg-pv-blue-900': update.id === selectedUpdateId.value }]),
                                    onClick: ($event) => (selectedUpdateId.value = update.id)
                                }, _toDisplayString(update.projectName), 11, _hoisted_4));
                            }), 128))
                        ]))
                        : (_openBlock(), _createElementBlock("p", _hoisted_5, " No se encontraron actualizaciones. "))
                ]),
                _createVNode(_Transition, {
                    "enter-active-class": "duration-200 ease-in",
                    "enter-from-class": "transform opacity-0",
                    "enter-to-class": "opacity-100",
                    "leave-active-class": "duration-200 ease-out",
                    "leave-from-class": "opacity-100",
                    "leave-to-class": "transform opacity-0"
                }, {
                    default: _withCtx(() => [
                        (selectedUpdateId.value)
                            ? (_openBlock(), _createBlock(BuildSprintUpdateReview, {
                                key: 0,
                                "update-id": selectedUpdateId.value,
                                class: "col-span-3"
                            }, null, 8, ["update-id"]))
                            : (_openBlock(), _createElementBlock("p", _hoisted_6, " Selecciona una actualización para ver su detalle. "))
                    ]),
                    _: 1
                })
            ]));
        };
    }
});
