import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex flex-col" };
const _hoisted_2 = ["name", "rows"];
const _hoisted_3 = { key: 0, class: "mt-0.5 text-sm text-red-600" };
import { watch, computed, toRefs } from 'vue';
import { useField } from 'vee-validate';
import KalioBaseInput from './kalio-base-input.vue';
import KalioTextAnswer from './kalio-text-answer.vue';
export default /*@__PURE__*/ _defineComponent({ ...{ inheritAttrs: false }, __name: 'kalio-long-text-input', props: { name: {}, rules: { default: '' }, modelValue: { default: '' }, value: { default: null }, label: { default: '' }, description: { default: '' }, error: { default: null }, rows: { default: 4 }, variant: { default: 'dark' }, onlyAnswer: { type: Boolean, default: false }, answerVariant: { default: 'semi-dark' } }, emits: ['update:modelValue'], setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const { name, rules, modelValue } = toRefs(props);
        const { value: inputValue, errorMessage, validate } = useField(name, rules, { initialValue: modelValue.value || props.value });
        watch([inputValue, modelValue], () => { emit('update:modelValue', inputValue.value); });
        watch(() => props.value, newValue => { if (newValue !== inputValue.value) {
            inputValue.value = newValue;
        } });
        const inputClasses = computed(() => { const classes = { dark: 'bg-pv-blue-900', 'semi-dark': 'bg-pv-blue-700' }; return classes[props.variant]; });
        const required = computed(() => props.rules.includes('required'));
        return (_ctx, _cache) => {
            return props.onlyAnswer ? (_openBlock(), _createBlock(KalioTextAnswer, { key: 0, label: props.label, answer: props.modelValue?.toString(), variant: props.answerVariant }, null, 8, ["label", "answer", "variant"])) : (_openBlock(), _createBlock(KalioBaseInput, { key: 1, label: props.label, description: props.description, required: required.value }, { input: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", { class: _normalizeClass(["appearance-none overflow-hidden rounded-md border-2 bg-pv-blue-900", _unref(errorMessage) || props.error ? 'border-red-600' : 'border-blue-gray-700 focus-within:ring-pv-light-blue focus-within:border-pv-light-blue']) }, [_withDirectives(_createElementVNode("textarea", _mergeProps(_ctx.$attrs, { "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(inputValue) ? inputValue.value = $event : null), name: props.name, class: ["-mb-1 w-full appearance-none p-2 outline-none", inputClasses.value], rows: props.rows, onBlur: _cache[1] || (_cache[1] = //@ts-ignore
                                    (...args) => _unref(validate) && _unref(validate)(...args)) }), null, 16, _hoisted_2), [[_vModelText, _unref(inputValue)]])], 2), _unref(errorMessage) || props.error ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(errorMessage) || props.error), 1)) : _createCommentVNode("", true)])]), _: 1 }, 8, ["label", "description", "required"]));
        };
    } });
