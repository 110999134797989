import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex flex-row gap-2" };
const _hoisted_2 = { class: "flex" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "flex flex-col justify-between pb-1" };
const _hoisted_5 = { class: "text-left" };
const _hoisted_6 = { class: "flex items-center gap-2" };
import KalioSocialMediaIcons from '@/components/shared/kalio-social-media-icons.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'demo-day-connect-founder-card',
    props: {
        founder: {}
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            const _component_country_flag = _resolveComponent("country-flag");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                        src: props.founder.avatarUrl,
                        alt: "founder photo",
                        class: "size-12 shrink-0 rounded-lg object-cover"
                    }, null, 8, _hoisted_3)
                ]),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(props.founder.fullName), 1),
                    _createElementVNode("div", _hoisted_6, [
                        (props.founder.country)
                            ? (_openBlock(), _createBlock(_component_country_flag, {
                                key: 0,
                                country: props.founder.country.code.toLowerCase(),
                                size: "small"
                            }, null, 8, ["country"]))
                            : _createCommentVNode("", true),
                        (props.founder.socialMedia)
                            ? (_openBlock(), _createBlock(KalioSocialMediaIcons, {
                                key: 1,
                                "social-media": props.founder.socialMedia,
                                "icon-classes": "size-4 mr-1"
                            }, null, 8, ["social-media"]))
                            : _createCommentVNode("", true)
                    ])
                ])
            ]));
        };
    }
});
