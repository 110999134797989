import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["data-url"];
import { onBeforeUnmount, onMounted } from 'vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'kalio-calendly-schedule', props: { meetingUrl: {}, prefillName: { default: '' }, prefillEmail: { default: '' }, prefillGuests: { default: () => [] } }, emits: ["event-scheduled"], setup(__props, { emit: __emit }) {
        const emit = __emit;
        const props = __props;
        function initCalendlyWidget() {
            let meetingUrl = props.meetingUrl;
            if (props.prefillGuests.length) {
                meetingUrl += `?guests=${props.prefillGuests.join(',')}`;
            }
            window.Calendly.initInlineWidget({ url: meetingUrl, parentElement: document.querySelector('.calendly-widget'), prefill: { name: props.prefillName, email: props.prefillEmail, guests: 'ex1@gmail,ex2@example.com' } });
        }
        async function waitCalendlyLoad(callback) { const POLLING_TIME_MS = 50; while (!window.hasOwnProperty('Calendly')) {
            await new Promise(resolve => setTimeout(resolve, POLLING_TIME_MS));
        } callback(); }
        function handleCalendlyScheduledEvent(e) { if (!e.data?.event?.startsWith('calendly'))
            return; const eventType = e.data.event.split('.')[1]; if (eventType !== 'event_scheduled')
            return; emit('event-scheduled', e.data.payload); }
        onMounted(() => { if (!document.getElementById('calendly-script')) {
            const calendlyScript = document.createElement('script');
            calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
            calendlyScript.setAttribute('async', 'true');
            calendlyScript.setAttribute('id', 'calendly-script');
            document.head.appendChild(calendlyScript);
        } window.addEventListener('message', handleCalendlyScheduledEvent); waitCalendlyLoad(initCalendlyWidget); });
        onBeforeUnmount(() => { window.removeEventListener('message', handleCalendlyScheduledEvent); });
        return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", { class: "calendly-widget size-full overflow-scroll", "data-url": props.meetingUrl }, null, 8, _hoisted_1); };
    } });
