import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { key: 0, class: "flex flex-col items-center" };
const _hoisted_2 = { class: "text-xl" };
const _hoisted_3 = { class: "flex items-center justify-center gap-2" };
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { format, differenceInMilliseconds } from 'date-fns';
import { useGroupMeetingStore } from '@/stores';
import KalioArrowButton from '@/components/shared/kalio-arrow-button.vue';
import WeeklyUpdateForm from '@/components/weekly-updates/form.vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'weekly-updates', setup(__props) { const groupMeetingStore = useGroupMeetingStore(); const { groupMeeting, selectedCompany } = storeToRefs(groupMeetingStore); const weeklyUpdates = computed(() => selectedCompany.value?.weeklyUpdates); function findCurrentWeeklyUpdateIndex() { const indexMatch = weeklyUpdates.value?.findIndex(update => update.groupMeetingId === groupMeeting.value.id); if (indexMatch !== -1)
        return indexMatch; const groupMeetingStartDate = new Date(groupMeeting.value.startDate); return weeklyUpdates.value?.reduce((closestIndex, update, index) => { const endDate = new Date(update.endDate); const diff = Math.abs(differenceInMilliseconds(endDate, groupMeetingStartDate)); const closestEndDate = new Date(weeklyUpdates.value[closestIndex].endDate); const closestDiff = Math.abs(differenceInMilliseconds(closestEndDate, groupMeetingStartDate)); return diff < closestDiff ? index : closestIndex; }, 0); } const currentIndex = ref(findCurrentWeeklyUpdateIndex()); const currentWeeklyUpdate = computed(() => weeklyUpdates.value[currentIndex.value || 0]); const currentPreparation = computed(() => selectedCompany.value?.groupMeetingPreparations.find(prep => prep.groupMeetingId === currentWeeklyUpdate.value.groupMeetingId)); const currentCommitment = computed(() => selectedCompany.value?.groupMeetingCommitments.find(commitment => commitment.groupMeetingId === currentWeeklyUpdate.value.groupMeetingId)); const nextCommitment = computed(() => { const currentCommitmentIndex = selectedCompany.value?.groupMeetingCommitments.findIndex(commitment => commitment.id === currentCommitment.value?.id); if (currentCommitmentIndex === undefined)
        return undefined; return selectedCompany.value?.groupMeetingCommitments[currentCommitmentIndex + 1]; }); return (_ctx, _cache) => { return _unref(selectedCompany) && currentWeeklyUpdate.value && currentIndex.value !== undefined ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, " Actualizaciones semanales de " + _toDisplayString(_unref(selectedCompany).name), 1), _createElementVNode("div", _hoisted_3, [_createVNode(KalioArrowButton, { disabled: currentIndex.value === 0, onClick: _cache[0] || (_cache[0] = $event => currentIndex.value--) }, null, 8, ["disabled"]), _createElementVNode("p", null, " Semana " + _toDisplayString(_unref(format)(currentWeeklyUpdate.value.startDate, 'd/M')) + " a " + _toDisplayString(_unref(format)(currentWeeklyUpdate.value.endDate, 'd/M')), 1), _createVNode(KalioArrowButton, { "is-right": "", disabled: currentIndex.value === weeklyUpdates.value.length - 1, onClick: _cache[1] || (_cache[1] = $event => currentIndex.value++) }, null, 8, ["disabled"])]), (_openBlock(), _createBlock(WeeklyUpdateForm, { key: currentWeeklyUpdate.value.id, class: "rounded border-2 border-blue-gray-900 p-3", "weekly-update": currentWeeklyUpdate.value, coliseum: currentWeeklyUpdate.value.groupMeeting, preparation: currentPreparation.value, commitment: currentCommitment.value, "next-commitment": nextCommitment.value }, null, 8, ["weekly-update", "coliseum", "preparation", "commitment", "next-commitment"]))])) : _createCommentVNode("", true); }; } });
