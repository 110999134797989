import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex h-96 w-full flex-col gap-4 rounded-lg bg-pv-blue-700 p-6" };
import { computed } from 'vue';
import KalioModal from '@/components/shared/kalio-modal.vue';
import KalioCopyTextButton from '@/components/shared/kalio-copy-text-button.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'share-company-update-modal',
    props: {
        companyName: {},
        month: {},
        metrics: {},
        plainTexts: {}
    },
    setup(__props) {
        const props = __props;
        const metricsString = computed(() => props.metrics.map((metric) => `- ${metric.name}: ${metric.value}`).join('\n'));
        const plainTextsString = computed(() => props.plainTexts.map((plainText) => `${plainText.name}:\n${plainText.value}`).join('\n\n'));
        const shareableText = `Hola,

Esta es la actualización de ${props.companyName} para el mes de ${props.month}.

Métricas:
${metricsString.value}

${plainTextsString.value}

Saludos,
`;
        return (_ctx, _cache) => {
            const _component_kalio_button = _resolveComponent("kalio-button");
            return (_openBlock(), _createBlock(KalioModal, null, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "text-2xl font-medium text-white" }, " Compartir actualización ", -1)),
                        _createElementVNode("p", { class: "overflow-y-scroll whitespace-pre-line rounded-lg border border-slate-700 bg-pv-blue-900 p-4 text-left" }, _toDisplayString(shareableText)),
                        _createVNode(_component_kalio_button, { class: "self-center" }, {
                            default: _withCtx(() => [
                                _createVNode(KalioCopyTextButton, {
                                    text: shareableText,
                                    "button-text": "Copiar",
                                    "icon-classes": "size-4 fill-current"
                                })
                            ]),
                            _: 1
                        })
                    ])
                ]),
                _: 1
            }));
        };
    }
});
