import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-3 md:gap-2" };
const _hoisted_2 = { class: "text-xl font-semibold text-slate-50" };
const _hoisted_3 = { class: "text-sm text-slate-50" };
const _hoisted_4 = { class: "flex flex-row gap-4 text-xs text-slate-300" };
const _hoisted_5 = { key: 0, class: "flex items-center gap-1" };
const _hoisted_6 = { class: "flex gap-1" };
import KalioListItemCard from '@/components/shared/kalio-list-item-card.vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'kalio-company-card', props: { company: {} }, setup(__props) { const props = __props; return (_ctx, _cache) => { const _component_inline_svg = _resolveComponent("inline-svg"); return _openBlock(), _createBlock(KalioListItemCard, { "avatar-url": props.company.isotypeUrl, "show-link": props.company.communityProfilePath }, { content: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(props.company.name), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(props.company.slogan), 1), _createElementVNode("div", _hoisted_4, [props.company.batch ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_inline_svg, { src: require('assets/images/icons/brand/platanus-isotype.svg'), class: "size-3" }, null, 8, ["src"]), _createElementVNode("span", null, _toDisplayString(props.company.batch.batchSeason), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_6, [_createVNode(_component_inline_svg, { src: require('assets/images/landing/world-gradient.svg'), class: "size-4" }, null, 8, ["src"]), _createElementVNode("span", null, _toDisplayString(props.company.country), 1)])])])]), _: 1 }, 8, ["avatar-url", "show-link"]); }; } });
