import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex w-full flex-row items-center justify-between py-3.5 pl-6 pr-4 hover:bg-pv-blue-800 focus:outline-none" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'demo-day-arrow-button',
    props: {
        text: {},
        textClasses: { default: 'text-base text-blue-gray-100' },
        iconClasses: { default: 'w-3 h-3' }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            const _component_inline_svg = _resolveComponent("inline-svg");
            return (_openBlock(), _createElementBlock("button", _hoisted_1, [
                _createElementVNode("span", {
                    class: _normalizeClass(props.textClasses)
                }, _toDisplayString(props.text), 3),
                _createVNode(_component_inline_svg, {
                    src: require('assets/images/icons/toggle-arrow.svg'),
                    class: _normalizeClass(["rotate-90 fill-current text-pv-yellow", props.iconClasses])
                }, null, 8, ["src", "class"])
            ]));
        };
    }
});
