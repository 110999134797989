import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "flex flex-col gap-1 rounded-lg bg-pv-blue-900 p-4" };
const _hoisted_2 = ["href"];
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { key: 1, class: "flex flex-col gap-1" };
import { computed } from 'vue';
import { format } from 'date-fns';
import KalioAccordionRow from '@/components/shared/kalio-accordion-row.vue';
import KalioRichText from '@/components/shared/kalio-rich-text.vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'startup-application-in-person-interview', props: { startupApplication: {} }, setup(__props) { const props = __props; const inPersonInterviewRecord = computed(() => props.startupApplication.inPersonInterviewRecord); return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", { href: `/internal/apply/review/startup_applications/${props.startupApplication.id}/in_person_interview`, class: "font-semibold transition-colors hover:text-pv-yellow" }, " Entrevista Presencial ", 8, _hoisted_2), !inPersonInterviewRecord.value ? (_openBlock(), _createElementBlock("p", _hoisted_3, " ❌ Aún no realizada ")) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("p", null, " ✅ Realizada el " + _toDisplayString(_unref(format)(inPersonInterviewRecord.value.date, 'dd/MM/yyyy')), 1), _createVNode(KalioAccordionRow, { title: "Notas" }, { default: _withCtx(() => [_createVNode(KalioRichText, { "rich-text": inPersonInterviewRecord.value.notes }, null, 8, ["rich-text"])]), _: 1 })]))]); }; } });
