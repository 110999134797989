import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "value", "checked", "name"];
const _hoisted_3 = { class: "absolute inset-0 rounded-full p-0.5 sm:p-1" };
import { ref, computed, useAttrs } from 'vue';
export default /*@__PURE__*/ _defineComponent({ ...{ inheritAttrs: false }, __name: 'kalio-radio-input', props: { label: { default: null }, modelValue: { type: [String, Boolean, null], default: null }, value: { type: [String, Boolean] }, name: {} }, emits: ["update:modelValue"], setup(__props, { emit: __emit }) { const attrs = useAttrs(); const props = __props; const emit = __emit; const id = ref(`${props.name}-${props.value}`); const checked = computed(() => props.modelValue === props.value); function updateModelValue(event) { const target = event.target; if (typeof props.value === 'boolean') {
        emit('update:modelValue', target.value === 'true');
    }
    else {
        emit('update:modelValue', target.value);
    } } return (_ctx, _cache) => { return _openBlock(), _createElementBlock("div", null, [_createElementVNode("label", { for: id.value, class: "group flex cursor-pointer flex-row items-center" }, [_createElementVNode("input", _mergeProps({ id: id.value, type: "radio", class: "peer hidden", value: _ctx.value, checked: checked.value, name: _ctx.name }, _unref(attrs), { onChange: updateModelValue }), null, 16, _hoisted_2), _createElementVNode("div", { class: _normalizeClass(["relative block size-3 rounded-full border ring-pv-yellow duration-100 ease-in-out peer-checked:ring-1 sm:size-4", checked.value ? 'border-pv-yellow' : 'border-pv-yellow/80']) }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", { class: _normalizeClass(["size-full rounded-full bg-white opacity-0 transition-opacity duration-100 ease-in-out", { 'opacity-100': checked.value }]) }, null, 2)])], 2), _ctx.label ? (_openBlock(), _createElementBlock("span", { key: 0, class: _normalizeClass(["ml-2 flex-1 text-xs text-blue-gray-50 sm:text-sm", { 'font-medium': checked.value }]) }, _toDisplayString(_ctx.label), 3)) : _createCommentVNode("", true)], 8, _hoisted_1)]); }; } });
