<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUrlSearchParams } from '@vueuse/core';
import { useInvestorApplicationsFiltersStore } from '../../stores';

const props = defineProps({
  demoDays: { type: Array, required: true },
  aasmStates: { type: Array, required: true },
  kinds: { type: Array, required: true },
  countries: { type: Array, required: true },
  loading: { type: Boolean, required: true },
});

const investorApplicationsFiltersStore = useInvestorApplicationsFiltersStore();
const { addFilters, initialFilters } = investorApplicationsFiltersStore;
const { appliedFilters } = storeToRefs(investorApplicationsFiltersStore);

if (Object.keys(appliedFilters.value).length === 0) {
  const initialDemoDay = props.demoDays.slice(-1)[0];
  addFilters({ demoDayId: initialDemoDay.id });
}

const formValues = ref(Object.keys(initialFilters).reduce((acc, key) => {
  acc[key] = appliedFilters.value[key] ?? initialFilters[key];

  return acc;
}, {}));

const params = useUrlSearchParams();
function updateParams() {
  Object.keys(params).forEach(key => delete params[key]);
  Object.entries(appliedFilters.value).forEach(([key, value]) => {
    params[key] = value;
  });
}

function applyFilters() {
  addFilters(formValues.value);
  updateParams();
}

const getCountryLabel = (code) => props.countries.find((country) => country.code === code).name;
const getDemoDayLabel = (id) => props.demoDays.find((demoDay) => demoDay.id === id).batchName;

</script>

<template>
  <div class="flex flex-col rounded-md border border-blue-gray-700 bg-pv-blue-900 p-4">
    <kalio-form-wrapper
      class="flex flex-col gap-4"
      @submit="applyFilters"
    >
      <div class="flex flex-col space-y-2">
        <kalio-select-input
          v-model="formValues.demoDayId"
          size="small"
          label="Generación del demo day"
          placeholder="Todos"
          name="demo_day_id"
          :options="demoDays.map(demoDay => demoDay.id)"
          :get-option-label="getDemoDayLabel"
          :required="false"
        />
        <kalio-select-input
          v-model="formValues.aasmState"
          size="small"
          label="Estados"
          placeholder="Todos"
          name="aasm_state"
          :options="aasmStates"
          :required="false"
        />
        <kalio-select-input
          v-model="formValues.kind"
          size="small"
          label="Tipo"
          placeholder="Todos"
          name="kind"
          :options="kinds"
          :required="false"
        />
        <kalio-select-input
          v-model="formValues.country"
          size="small"
          label="Países"
          placeholder="Todos"
          name="country"
          :options="countries.map(country => country.code)"
          :get-option-label="getCountryLabel"
          :required="false"
        />
      </div>
      <kalio-button
        label="Aplicar filtros"
        class="w-full"
        :loading="loading"
        type="submit"
      />
    </kalio-form-wrapper>
  </div>
</template>
