import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "flex max-w-xs flex-col gap-10 lg:max-w-xl" };
const _hoisted_2 = { class: "mb-3 flex w-full flex-col gap-5 px-2" };
const _hoisted_3 = { class: "text-3xl text-slate-50" };
const _hoisted_4 = ["value"];
import { ref } from 'vue';
import { formatISO, parseISO } from 'date-fns';
import KalioShortTextInput from '@/components/form/kalio-short-text-input.vue';
import KalioTrixEditor from '@/components/form/kalio-trix-editor.vue';
import KalioRadioInputs from '@/components/form/kalio-radio-inputs.vue';
import KalioFileInput from '@/components/form/kalio-file-input.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'company-update-form',
    props: {
        actionPath: {},
        companyUpdate: {}
    },
    setup(__props) {
        const props = __props;
        const monthParam = new URLSearchParams(window.location.search).get('month');
        const month = monthParam ? parseISO(monthParam) : new Date();
        const formattedMonth = month.toLocaleDateString('es-CL', { month: 'long', year: 'numeric' });
        const companyUpdate = ref({
            month: formatISO(month),
            pivot: false,
            ...props.companyUpdate,
        });
        return (_ctx, _cache) => {
            const _component_kalio_back_button = _resolveComponent("kalio-back-button");
            const _component_kalio_button = _resolveComponent("kalio-button");
            const _component_kalio_form_wrapper = _resolveComponent("kalio-form-wrapper");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_kalio_back_button, {
                    class: "self-start",
                    href: `/tracking/company_updates`
                }),
                _createVNode(_component_kalio_form_wrapper, {
                    action: props.actionPath,
                    enctype: "multipart/form-data",
                    method: props.companyUpdate ? 'patch' : 'post',
                    class: "flex flex-col items-center"
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("h1", _hoisted_3, [
                                _cache[14] || (_cache[14] = _createElementVNode("span", { class: "font-medium" }, " Actualización mensual, ", -1)),
                                _createTextVNode(" " + _toDisplayString(_unref(formattedMonth)), 1)
                            ]),
                            _createElementVNode("input", {
                                type: "hidden",
                                name: "company_update[month]",
                                value: companyUpdate.value.month
                            }, null, 8, _hoisted_4),
                            _createVNode(KalioShortTextInput, {
                                modelValue: companyUpdate.value.grossMargin,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((companyUpdate.value.grossMargin) = $event)),
                                label: "Margen Bruto",
                                name: "company_update[gross_margin]",
                                description: "Diferencia entre los ingresos por ventas y el costo de bienes vendidos, como porcentaje de los\n                       ingresos por ventas.",
                                placeholder: "0%",
                                rules: "required|numeric|min_value:0|max_value:100"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioShortTextInput, {
                                modelValue: companyUpdate.value.revenue,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((companyUpdate.value.revenue) = $event)),
                                label: "Ingreso mensual",
                                name: "company_update[revenue]",
                                description: "Ingreso por la venta del producto o servicio, en el mes.",
                                placeholder: "00,00",
                                rules: "required|numeric"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioShortTextInput, {
                                modelValue: companyUpdate.value.customerLifetimeValue,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((companyUpdate.value.customerLifetimeValue) = $event)),
                                label: "LTV",
                                name: "company_update[customer_lifetime_value]",
                                description: "Tiempo de vida de un cliente: cuánto genera de ganancia un cliente.",
                                placeholder: "00,00"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioShortTextInput, {
                                modelValue: companyUpdate.value.customerAcquisitionCost,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((companyUpdate.value.customerAcquisitionCost) = $event)),
                                label: "CAC",
                                name: "company_update[customer_acquisition_cost]",
                                description: "Costo de adquisición de clientes: cuánto cuesta adquirir un nuevo cliente.",
                                placeholder: "00,00",
                                rules: "required|numeric"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioShortTextInput, {
                                modelValue: companyUpdate.value.cashInBank,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((companyUpdate.value.cashInBank) = $event)),
                                label: "Cash in bank",
                                name: "company_update[cash_in_bank]",
                                description: "Dinero en el banco, al final del periodo de reporte.",
                                placeholder: "00,00",
                                rules: "required|numeric"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioShortTextInput, {
                                modelValue: companyUpdate.value.netCashBurn,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((companyUpdate.value.netCashBurn) = $event)),
                                label: "Net cash burn",
                                name: "company_update[net_cash_burn]",
                                description: "Diferencia entre los ingresos y todo lo que la empresa gasta en el mes.",
                                placeholder: "00,00",
                                rules: "required|numeric"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioShortTextInput, {
                                modelValue: companyUpdate.value.headcount,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((companyUpdate.value.headcount) = $event)),
                                label: "Headcount",
                                name: "company_update[headcount]",
                                description: "Cantidad de personas contratadas en la empresa.",
                                placeholder: "00",
                                rules: "required|numeric"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioShortTextInput, {
                                modelValue: companyUpdate.value.totalClients,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((companyUpdate.value.totalClients) = $event)),
                                label: "Clientes totales",
                                name: "company_update[total_clients]",
                                description: "Cantidad de clientes pagados que tiene la empresa.",
                                placeholder: "00",
                                rules: "required|numeric"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioRadioInputs, {
                                modelValue: companyUpdate.value.pivot,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((companyUpdate.value.pivot) = $event)),
                                label: "¿Pivoteaste el último mes?",
                                name: "company_update[pivot]",
                                values: [true, false],
                                "radio-labels": ['Sí', 'No']
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioTrixEditor, {
                                modelValue: companyUpdate.value.highlights,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((companyUpdate.value.highlights) = $event)),
                                label: "Highlights",
                                name: "company_update[highlights]",
                                description: "¿Qué cosas destacas, desde el último reporte que enviaste?"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioTrixEditor, {
                                modelValue: companyUpdate.value.lowlights,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((companyUpdate.value.lowlights) = $event)),
                                label: "Lowlights",
                                name: "company_update[lowlights]",
                                description: "¿Qué cosas no han salido bien, desde el último reporte que enviaste?"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioTrixEditor, {
                                modelValue: companyUpdate.value.jobOfferings,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((companyUpdate.value.jobOfferings) = $event)),
                                label: "Jobs open",
                                name: "company_update[job_offerings]",
                                description: "¿Tienes cargos abiertos? Si es que tienes, menciona cada uno con detalle o adjunta el link."
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioTrixEditor, {
                                modelValue: companyUpdate.value.fundingRound,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event) => ((companyUpdate.value.fundingRound) = $event)),
                                label: "Ronda de financiamiento",
                                name: "company_update[funding_round]",
                                description: "¿Estás pensando levantar una nueva ronda de financiamiento? Si es así, ¿para qué fecha?"
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioTrixEditor, {
                                modelValue: companyUpdate.value.asks,
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event) => ((companyUpdate.value.asks) = $event)),
                                label: "Asks",
                                name: "company_update[asks]",
                                description: "¿Hay algo en lo que podamos ayudarte? Ejemplo: si necesitas ponerte en contacto con otros\n                       emprendedores de tu industria."
                            }, null, 8, ["modelValue"]),
                            _createVNode(KalioFileInput, {
                                src: companyUpdate.value.reportUrl,
                                rules: "ext:pdf",
                                name: "company_update[report]",
                                label: "Reporte en PDF",
                                description: "Si tienes info adicional, puedes agregar tu reporte en PDF con más detalles. Opcional."
                            }, null, 8, ["src"]),
                            _createVNode(_component_kalio_button, {
                                label: "Enviar",
                                type: "submit",
                                class: "mt-10 w-full self-center lg:w-80"
                            })
                        ])
                    ]),
                    _: 1
                }, 8, ["action", "method"])
            ]));
        };
    }
});
