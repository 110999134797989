import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "mt-20 flex w-full max-w-lg flex-col space-y-12 sm:items-center" };
const _hoisted_2 = { class: "flex max-w-md flex-col items-center justify-start gap-5" };
const _hoisted_3 = ["src"];
const _hoisted_4 = { class: "flex flex-col items-center justify-start gap-2" };
const _hoisted_5 = { class: "text-base font-medium text-blue-gray-50" };
const _hoisted_6 = { class: "text-sm font-medium text-blue-gray-50" };
const _hoisted_7 = { key: 0 };
import { ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import officeHourOfferingsApi from '@/api/office_hour_offerings';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'user-profile', props: { user: {}, officeHourOffering: {} }, setup(__props) { const props = __props; const isOfficeHourOfferingPresent = ref(props.officeHourOffering !== undefined); const { mutate: createOfficeHourOffering, isSuccess, isLoading, reset } = useMutation(() => officeHourOfferingsApi.create({ description: '-', userId: props.user.id, active: false }), { onSuccess: () => { isOfficeHourOfferingPresent.value = true; } }); return (_ctx, _cache) => { const _component_kalio_button = _resolveComponent("kalio-button"); return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", { class: "size-24 rounded-full object-cover", src: _ctx.user.avatarUrl }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.user.fullName), 1), _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.user.email), 1)])]), isOfficeHourOfferingPresent.value ? (_openBlock(), _createElementBlock("p", _hoisted_7, " Este usuario ya tiene Office Hour creada ")) : (_openBlock(), _createBlock(_component_kalio_button, { key: 1, label: "Invitar a dar Office Hour", "loading-text": "Creando Office Hour...", loading: _unref(isLoading), onClick: _unref(createOfficeHourOffering) }, null, 8, ["loading", "onClick"])), _unref(isSuccess) ? (_openBlock(), _createBlock(KalioFeedbackModal, { key: 2, title: "Office Hour creada exitosamente", body: "Se enviará un mail al usuario invitándolo a configurar su Office Hour", onAccept: _unref(reset) }, null, 8, ["onAccept"])) : _createCommentVNode("", true)]); }; } });
