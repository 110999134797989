import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "flex flex-col" };
const _hoisted_2 = { key: 0, class: "mt-0.5 text-sm text-red-600" };
import { watch, computed, toRefs } from 'vue';
import { useField } from 'vee-validate';
import KalioRichText from '@/components/shared/kalio-rich-text.vue';
import TrixAttachmentsApi from '../../api/trix_attachments';
import KalioBaseInput from './kalio-base-input.vue';
import KalioTextAnswer from './kalio-text-answer.vue';
import VueTrix from './vue-trix.vue';
export default /*@__PURE__*/ _defineComponent({ __name: 'kalio-trix-editor', props: { modelValue: {}, label: { default: '' }, description: { default: '' }, name: { default: '' }, rules: { default: '' }, variant: { default: 'dark' }, size: { default: 'base' }, onlyAnswer: { type: Boolean, default: false }, answerVariant: { default: 'semi-dark' } }, emits: ['update:modelValue'], setup(__props, { emit: __emit }) { const props = __props; const emit = __emit; const { name, rules } = toRefs(props); const { value: fieldValue, errorMessage, handleChange } = useField(name, rules, { initialValue: props.modelValue }); const required = computed(() => props.rules?.includes('required') ?? false); function handleAttachmentAdd({ attachment }) { if (!attachment.file)
        return; TrixAttachmentsApi.create(attachment).then(({ url, contentType }) => { attachment.setAttributes({ url, href: url, contentType }); }); } watch(fieldValue, newValue => { emit('update:modelValue', newValue); }); return (_ctx, _cache) => { return props.onlyAnswer ? (_openBlock(), _createBlock(KalioTextAnswer, { key: 0, label: props.label, variant: props.answerVariant, size: props.size }, { default: _withCtx(() => [_createVNode(KalioRichText, { "rich-text": props.modelValue }, null, 8, ["rich-text"])]), _: 1 }, 8, ["label", "variant", "size"])) : (_openBlock(), _createBlock(KalioBaseInput, { key: 1, label: props.label, description: props.description, required: required.value, size: props.size }, { input: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(VueTrix, _mergeProps({ "src-content": props.modelValue }, _ctx.$attrs, { "input-name": props.name, class: [`kalio-vue-trix-editor kalio-vue-trix-editor--${props.variant}`, { 'kalio-vue-trix-editor--error': _unref(errorMessage) }], onInput: _unref(handleChange), onTrixAttachmentAdd: handleAttachmentAdd }), null, 16, ["src-content", "input-name", "class", "onInput"]), _unref(errorMessage) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(errorMessage)), 1)) : _createCommentVNode("", true)])]), _: 1 }, 8, ["label", "description", "required", "size"])); }; } });
