import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import buildSprintUpdatesApi from '@/build_sprint_pack/api/build_sprint_updates';
import KalioFeedbackModal from '@/components/shared/kalio-feedback-modal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'update-form',
    props: {
        buildSprintUpdate: {},
        isLastUpdate: { type: Boolean },
        isEditing: { type: Boolean, default: false }
    },
    setup(__props) {
        const props = __props;
        const dynamicBuildSprintUpdate = ref({
            ...props.buildSprintUpdate,
        });
        const { mutate: submit, isLoading, isSuccess } = useMutation(() => {
            if (props.isEditing) {
                return buildSprintUpdatesApi.update(dynamicBuildSprintUpdate.value.id, dynamicBuildSprintUpdate.value);
            }
            return buildSprintUpdatesApi.create(dynamicBuildSprintUpdate.value);
        });
        function redirectToHome() {
            window.location.href = '/build_sprint';
        }
        return (_ctx, _cache) => {
            const _component_kalio_long_text_input = _resolveComponent("kalio-long-text-input");
            const _component_kalio_form_section = _resolveComponent("kalio-form-section");
            const _component_kalio_short_text_input = _resolveComponent("kalio-short-text-input");
            const _component_kalio_button = _resolveComponent("kalio-button");
            const _component_vee_form = _resolveComponent("vee-form");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(_component_vee_form, {
                    class: "mb-4 flex flex-col space-y-4 sm:mb-12 sm:space-y-8",
                    onSubmit: _unref(submit)
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_kalio_form_section, { title: "Métricas de esta semana" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_kalio_long_text_input, {
                                    modelValue: dynamicBuildSprintUpdate.value.goalProgress,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((dynamicBuildSprintUpdate.value.goalProgress) = $event)),
                                    rules: "required|max:300",
                                    name: "goal_progress",
                                    label: "¿Cuál fue el avance de esta semana? ¿Qué tan cerca estás de la meta?"
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_kalio_long_text_input, {
                                    modelValue: dynamicBuildSprintUpdate.value.kpiVariationReason,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((dynamicBuildSprintUpdate.value.kpiVariationReason) = $event)),
                                    rules: "required|max:300",
                                    name: "kpi_variation_reason",
                                    label: "¿Cuál fue la razón de ese avance?"
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_kalio_form_section, { title: "Aprendizajes" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_kalio_short_text_input, {
                                    modelValue: dynamicBuildSprintUpdate.value.usersTalked,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((dynamicBuildSprintUpdate.value.usersTalked) = $event)),
                                    rules: "required|numeric",
                                    name: "users_talked",
                                    label: "Número de usuarios con los que hablaron esta semana"
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_kalio_long_text_input, {
                                    modelValue: dynamicBuildSprintUpdate.value.learningsFromUsers,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((dynamicBuildSprintUpdate.value.learningsFromUsers) = $event)),
                                    rules: "required|max:300",
                                    name: "learnings_from_users",
                                    label: "¿Qué aprendieron de ellos?"
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_kalio_short_text_input, {
                                    modelValue: dynamicBuildSprintUpdate.value.teamMorale,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((dynamicBuildSprintUpdate.value.teamMorale) = $event)),
                                    rules: "required|numeric|min_value:0|max_value:10",
                                    name: "team_morale",
                                    label: "En escala de 1 a 10, ¿cómo está la moral del equipo? (10 es lo mejor)"
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_kalio_long_text_input, {
                                    modelValue: dynamicBuildSprintUpdate.value.biggestObstacle,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((dynamicBuildSprintUpdate.value.biggestObstacle) = $event)),
                                    rules: "required|max:300",
                                    name: "biggest_obstacle",
                                    label: "¿Cuál fue el mayor obstáculo o lo más difícil de esta semana?"
                                }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                        }),
                        (!props.isLastUpdate)
                            ? (_openBlock(), _createBlock(_component_kalio_form_section, {
                                key: 0,
                                title: "Objetivos"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_kalio_long_text_input, {
                                        modelValue: dynamicBuildSprintUpdate.value.weeklyGoals,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((dynamicBuildSprintUpdate.value.weeklyGoals) = $event)),
                                        rules: "required|max:300",
                                        name: "weekly_goals",
                                        label: "Ingresa 1 a 3 objetivos para la semana que viene",
                                        description: "Piensa en la meta que tienes para este Build Sprint."
                                    }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        (_ctx.isLastUpdate)
                            ? (_openBlock(), _createBlock(_component_kalio_form_section, {
                                key: 1,
                                title: "Cierre"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_kalio_short_text_input, {
                                        modelValue: dynamicBuildSprintUpdate.value.goalCompleted,
                                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((dynamicBuildSprintUpdate.value.goalCompleted) = $event)),
                                        rules: "required|max:300",
                                        name: "goal_completed",
                                        label: "¿Cumpliste la meta del Build Sprint? Si no la cumpliste, ¿por qué crees que fue?"
                                    }, null, 8, ["modelValue"]),
                                    _createVNode(_component_kalio_short_text_input, {
                                        modelValue: dynamicBuildSprintUpdate.value.gotAnyBenefit,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((dynamicBuildSprintUpdate.value.gotAnyBenefit) = $event)),
                                        rules: "required|max:300",
                                        name: "got_any_benefit",
                                        label: "Durante el Build Sprint, obtuviste algún beneficio importante para\n                  tu startup/proyecto que nos quieras contar?",
                                        description: "Por ejemplo: Encontraste co-founder, recibiste buen feedback de tu producto,\n                      conseguiste clientes dentro de la comunidad, hiciste alguna alianza,\n                      aprendiste de un tema, decidiste pivotear, etc."
                                    }, null, 8, ["modelValue"]),
                                    _createVNode(_component_kalio_short_text_input, {
                                        modelValue: dynamicBuildSprintUpdate.value.experienceRating,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((dynamicBuildSprintUpdate.value.experienceRating) = $event)),
                                        rules: "required",
                                        name: "experience_rate",
                                        label: "¿Cómo evalúas tu experiencia en el Build Sprint?"
                                    }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                            }))
                            : _createCommentVNode("", true),
                        _createVNode(_component_kalio_button, {
                            label: props.isEditing ? 'Guardar' : 'Enviar',
                            loading: _unref(isLoading),
                            class: "mt-4 w-full sm:w-auto sm:self-center sm:px-32"
                        }, null, 8, ["label", "loading"])
                    ]),
                    _: 1
                }, 8, ["onSubmit"]),
                (_unref(isSuccess))
                    ? (_openBlock(), _createBlock(KalioFeedbackModal, {
                        key: 0,
                        title: _ctx.isEditing ? 'Reporte semanal guardado.' : 'Reporte semanal creado.',
                        accept: "Ok",
                        onAccept: redirectToHome
                    }, null, 8, ["title"]))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
