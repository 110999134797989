import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "container"
};
const _hoisted_2 = { class: "flex flex-col gap-2" };
const _hoisted_3 = { key: 2 };
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import i18n from '@/plugins/i18n';
import { useUIStore } from '@/stores';
import { useConnectionsStore } from '@/demo_day_pack/stores';
import DemoDayConnectCompanyCard from '@/demo_day_pack/components/demo-day-connect-company-card.vue';
import DemoDayConnectModal from '@/demo_day_pack/components/demo-day-connect-modal.vue';
import DemoDayConnectInvestorForm from '@/demo_day_pack/components/demo-day-connect-investor-form.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'demo-day-connect',
    props: {
        locale: {},
        demoDay: {},
        demoDayCompanies: {},
        batchSeason: {}
    },
    setup(__props) {
        const props = __props;
        i18n.global.locale = props.locale;
        const selectedCompany = ref(null);
        const isModalOpen = ref(false);
        const { t } = useI18n();
        const uiStore = useUIStore();
        const { setConnections } = useConnectionsStore();
        const { investorInfo, connections } = storeToRefs(useConnectionsStore());
        const isInvestorCreated = ref(!!investorInfo.value);
        function openModal(company) {
            selectedCompany.value = company;
            isModalOpen.value = true;
        }
        function closeModal() {
            isModalOpen.value = false;
            selectedCompany.value = null;
        }
        function onInvestorCreated() {
            isInvestorCreated.value = true;
        }
        function onConnect(connection) {
            setConnections([...connections.value || [], connection]);
            const connectedCompany = props.demoDayCompanies.find(company => company.id === connection.demoDayCompanyId);
            if (!connectedCompany)
                return;
            uiStore.toast({
                message: t('demoDay.connections.connectNotification', { companyName: connectedCompany.company.name }),
                type: 'success',
                duration: 3000,
                position: 'top',
            });
            selectedCompany.value = connectedCompany;
            isModalOpen.value = false;
        }
        function isCompanyConnected(company) {
            return (connections.value || []).some(connection => connection.demoDayCompanyId === company.id);
        }
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (isModalOpen.value)
                    ? (_openBlock(), _createBlock(DemoDayConnectModal, {
                        key: 0,
                        "demo-day-company": selectedCompany.value,
                        "is-connected": isCompanyConnected(selectedCompany.value),
                        onConnect: onConnect,
                        onClose: closeModal
                    }, null, 8, ["demo-day-company", "is-connected"]))
                    : _createCommentVNode("", true),
                (isInvestorCreated.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "mb-4 text-xl font-bold" }, " Platanus Connect ", -1)),
                        _createElementVNode("div", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.demoDayCompanies, (company) => {
                                return (_openBlock(), _createBlock(DemoDayConnectCompanyCard, {
                                    key: company.id,
                                    "demo-day-company": company,
                                    "is-connected": isCompanyConnected(company),
                                    onClick: ($event) => (openModal(company))
                                }, null, 8, ["demo-day-company", "is-connected", "onClick"]));
                            }), 128))
                        ])
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(DemoDayConnectInvestorForm, { onCreate: onInvestorCreated })
                    ]))
            ], 64));
        };
    }
});
