<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';

const props = defineProps({
  application: { type: Object, required: true },
  isCurrent: { type: Boolean },
});
defineEmits(['set-application']);

const fullName = computed(() => props.application.user.fullName);
const createdAt = computed(() => format(props.application.createdAt, 'dd/MM/yyyy HH:mm'));
</script>

<template>
  <button
    class="flex cursor-pointer flex-col rounded-md p-2"
    :class="{
      'pointer-events-none bg-pv-blue-700': isCurrent,
      'hover:bg-pv-blue-800': !isCurrent
    }"
    @click="$emit('set-application')"
  >
    <span class="mb-2 text-sm">
      {{ fullName }}
    </span>
    <div class="flex items-center">
      <inline-svg
        :src="require('assets/images/icons/calendar.svg')"
        class="mr-0.5 size-3 fill-current"
      />
      <span class="text-xs">
        {{ createdAt }}
      </span>
    </div>
  </button>
</template>
