<script setup>
import { computed, watch, nextTick } from 'vue';
import { useInfiniteQuery } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import KalioSpinner from '@/components/shared/kalio-spinner.vue';
import { useInvestorApplicationStore, useInvestorApplicationsFiltersStore } from '../stores';
import demoDayInvestorApplicationsApi from '../api/demo_day_investor_applications';
import DemoDayInternalInvestorApplicationsList from '../components/internal/demo-day-investor-applications-list';
import DemoDayInternalInvestorApplicationsFilters from '../components/internal/demo-day-investor-applications-filters';
import DemoDayInternalInvestorApplicationReview from '../components/internal/demo-day-investor-application-review';

function useInvestorApplicationsQuery(
  { filters, firstPageToFetch = 1 },
  options = {},
) {
  return useInfiniteQuery({
    queryKey: ['demoDayInvestorApplications', filters],
    queryFn: ({ pageParam = firstPageToFetch }) => demoDayInvestorApplicationsApi
      .getAll(pageParam, filters.value),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    ...options,
  });
}

const props = defineProps({
  demoDays: { type: Array, required: true },
  aasmStates: { type: Array, required: true },
  kinds: { type: Array, required: false, default: () => ['angel', 'fund'] },
  countries: { type: Array, required: true },
});

const investorApplicationStore = useInvestorApplicationStore();
const {
  currentInvestorApplication,
  investorApplications,
  currentInvestorApplicationIndex,
  currentInvestorApplicationId,
} = storeToRefs(investorApplicationStore);
const {
  previousInvestorApplication,
  nextInvestorApplication,
  goToInvestorApplication,
  updateCurrentInvestorApplication,
  setInvestorApplications,
} = investorApplicationStore;

const investorApplicationsFiltersStore = useInvestorApplicationsFiltersStore();
const { appliedFilters } = storeToRefs(investorApplicationsFiltersStore);

const firstPageToFetch = new URLSearchParams(window.location.search).get('page') || 1;
const {
  data: investorApplicationsResponse,
  fetchNextPage,
  isFetchingNextPage,
  isLoading,
} = useInvestorApplicationsQuery({ filters: appliedFilters, firstPageToFetch });

const isPagesEmpty = computed(
  () => (investorApplicationsResponse.value?.pages || [])
    .map((page) => page.demoDayInvestorApplications.length)
    .flat().length === 0,
);

watch(investorApplicationsResponse, (newData) => nextTick(
  () => setInvestorApplications(
    (newData?.pages || []).map(page => page.demoDayInvestorApplications).flat(),
  ),
));

const totalCount = computed(() => investorApplicationsResponse.value?.pages?.[0]?.totalCount || 0);
const currentPageItem = computed(() => {
  const currentPage = investorApplicationsResponse.value?.pages?.find(page => page.demoDayInvestorApplications.some(
    application => application.id === currentInvestorApplicationId.value,
  ))?.currentCursor || 1;
  const perPage = investorApplicationsResponse.value?.pages[0]?.perPage || 0;

  return (Number(currentPage) - 1) * Number(perPage) +
    currentInvestorApplicationIndex.value + 1;
});

const PAGE_FETCH_DISTANCE = 5;
watch(currentInvestorApplicationId, () => {
  if (currentInvestorApplicationIndex.value >= investorApplications.value.length -
      PAGE_FETCH_DISTANCE && !isFetchingNextPage.value) {
    fetchNextPage();
  }
});

const commentCreated = (comment) => {
  const comments = [...currentInvestorApplication.value.comments, comment];
  updateCurrentInvestorApplication({ ...currentInvestorApplication.value, comments });
};

</script>

<template>
  <div class="grid w-full grid-cols-4 gap-4">
    <div class="col-span-1 flex flex-col">
      <demo-day-internal-investor-applications-filters
        class="mb-4"
        :demo-days="props.demoDays"
        :aasm-states="props.aasmStates"
        :kinds="props.kinds"
        :countries="props.countries"
        :loading="isFetchingNextPage"
      />
      <demo-day-internal-investor-applications-list
        :loading="isLoading"
        :loading-bottom="isFetchingNextPage"
        @set-application="goToInvestorApplication"
        @scrolled-to-last="fetchNextPage"
      />
    </div>
    <div class="col-span-3 flex flex-col items-center space-y-4 rounded-lg border border-blue-gray-700 bg-pv-blue-900 p-6">
      <div
        v-if="isLoading"
        class="flex h-10 items-center justify-center"
      >
        <kalio-spinner />
      </div>
      <template v-else>
        <template v-if="currentInvestorApplication">
          <div class="flex flex-row items-center space-x-10">
            <button
              class="rounded-xl bg-gray-700 p-1"
              @click="previousInvestorApplication"
            >
              <inline-svg
                :src="require('assets/images/arrow-left.svg')"
                class="size-3 fill-pv-white"
              />
            </button>
            <label class="text-sm font-medium text-blue-gray-300">
              ({{ currentPageItem }} / {{ totalCount }})
            </label>
            <button
              class="rounded-xl bg-gray-700 p-1"
              @click="nextInvestorApplication"
            >
              <inline-svg
                :src="require('assets/images/arrow-right.svg')"
                class="size-3 fill-pv-white"
              />
            </button>
          </div>
          <demo-day-internal-investor-application-review
            v-if="!isPagesEmpty && currentInvestorApplication"
            :key="currentInvestorApplicationId"
            :application="currentInvestorApplication"
            @on-change="updateCurrentInvestorApplication"
            @comment-created="commentCreated"
          />
        </template>
        <span v-else>
          Ninguna postulación cumple con los filtros
        </span>
      </template>
    </div>
  </div>
</template>
